@import 'src/theme/variables';

.mobileFilterBarList {
  display: flex;
  flex-direction: column;
}

.mobileFilterBar {
  background-color: $stone-lightest;
}
