@import 'src/theme/variables';

.header {
  font-weight: 700;
  font-size: $font-size-24;
  margin-bottom: $space-24;
}

.link_back {
  margin-bottom: $space-24;


  button {
    padding-left: 0;
  }
  width: auto;
}

.show_details_container {
  padding: $space-32;
  background: white;
  border-radius: $button-border-radius;
}
.product_popup_btn{
  width: auto
}
