@import 'src/theme/variables';

.container {
  display: flex;
  gap: $space-8;
  align-items: center;
  justify-content: center;

  .ration_option {
    margin: $space-10 $space-8;
  }
}
