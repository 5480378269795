@import 'src/theme/variables';

.buttons_container {
  display: flex;
  overflow: auto;
  margin-top: $default--margin;
}

.buttons_container::-webkit-scrollbar {
  display: none;
}

.buttons_slider {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
}
