@import 'src/theme/variables';

.multiSelectOptions {
  max-height: 324px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: inset 0 -15px 12px -15px rgba(0, 0, 0, 0.17);
}

.multiSelectItem {
  display: block;
  line-height: 0;
  padding: 0.25rem; // we add a padding to give the background-color some space
  margin: 0 -0.25rem; // ... and we revert it so that everything stays in place
}

.multiSelectFooter {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.linkBtn {
  color: $brand-orange;
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
}
