@import 'src/theme/variables';

.slider_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-height: 700px;
}

.arrow_button {
  padding: $space-8;
  cursor: pointer;

  .arrow_up {
    transform: rotate(180deg);
  }
}

.arrow_button:disabled {
  opacity: 0.25;
  cursor: not-allowed;
}

.hidden {
  display: none;
}

.image_container {
  width: 120px;
  height: 100%;
  overflow: hidden;
  position: relative;

  &.show_arrows {
    height: calc(100% - $space-48 * 2);
  }
}

.images_wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  transition: transform 0.3s ease-in-out;
}

.add_button {
  width: 120px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: $space-40;

  .plus_icon {
    background-color: $white;
    width: $space-40;
    height: $space-40;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.upload_input {
  display: none;
}

@media (max-width: $viewport--max-width-ms) {
  .arrow_button {
    display: none;
  }

  .image_container {
    width: 100%;
    height: fit-content;
    position: initial;
  }

  .images_wrapper {
    flex-direction: row;
    gap: $space-24;
    transform: none !important;
    transition: none;
    overflow-y: scroll;
  }
}

.placeholder_cards {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .card {
    width: 120px;
    height: 150px;
    background-color: $white;
  }

  @media (max-width: $viewport--max-width-ms) {
    display: none;
  }
}
