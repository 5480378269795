@import 'src/theme/variables';
@import '~@hse24/shared-components/styles/breakpoints';

.container {
  width: 100%;
  height: 100%;
  background-color: $white-lilac;

  .commission_table_wrapper {
    display: flex;
    margin-bottom: $container--padding;

    h1 {
      font-weight: 800;
      font-size: 24px;
      margin-right: 15px;

      @media (max-width: $viewport--max-width-ms) {
        margin-bottom: 10px;
      }
    }

    @media (max-width: $viewport--max-width-ms) {
      flex-direction: column;
    }
  }
}

.header {
  font-weight: 800;
  font-size: 24px;
  margin-right: 15px;
  margin-bottom: $container--padding;

  @media (max-width: $viewport--max-width-ms) {
    margin-bottom: 10px;
  }
}


.table_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-top: 2rem;

  h1 {
    align-self: flex-end;
    font-size: 24px;
    font-weight: 900;

    @media (max-width: $viewport--max-width-ms) {
      align-self: flex-start;
    }
  }

  .table_actions {
    .button {
      @media (max-width: $viewport--max-width-ms) {
        margin: 1rem 0 3rem 0;
      }
    }

    @media (max-width: $viewport--max-width-ms) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  @media (max-width: $viewport--max-width-ms) {
    flex-direction: column;
  }
}
