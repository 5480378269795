@import 'src/theme/variables';

.video {
  cursor: pointer;

  .preview_image {
    aspect-ratio: 16/9;
    background: $lightgrey-light;
    border-radius: .25rem;
    object-fit: cover;

    &.row {
      height: 4.5rem;

      img {
        width: unset;
        aspect-ratio: 16/9;
        height: 4.5rem;
        object-fit: cover;
        border-radius: .25rem;
      }
    }

    &.column {
      width: 100%;

      img {
        width: 100%;
        height: unset;
        aspect-ratio: 16/9;
        object-fit: cover;
        border-radius: .25rem;
      }
    }
  }

  .video_details {
    flex-grow: 2;
  }
}
