@import 'src/theme/variables';

.container {
  background: $lightgrey-lightest;
  padding: $space-8 $space-12;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $space-4;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    color: $iron;
  }

  .checkmark_icon {
    color: $status-winning-green;
    display: flex;
    justify-content: center;
    align-items: center;
    height: unset !important;
    width: unset !important;
  }

  span {
    font-size: $font-size-16;
    line-height: 24px;
    color: $status-winning-green;

    &.draft {
      color: #ed6c02;
    }

    &.unpublished {
      color: $iron;
    }

    &.unknown {
      color: black;
    }
  }
}

