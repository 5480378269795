@import 'src/theme/variables';

$radio-button-padding: 10px;

.body {
  padding: 0 $space-8;
}

.radio_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .radio_option {
    svg {
      //stroke-width: 100px;
    }
  }
}

.input {
  border-radius: $space-4;
  margin-top: $space-32;
  margin-bottom: $space-8;
}

.hint {
  color: $iron;
  font-size: $font-size-14;
  line-height: 21px;

  code {
    background: $lightgrey-lightest;
  }
}

.picture {
  margin-top: $space-32;
}
