@import 'src/theme/variables';

.wrapper {
  padding-top: $space-64;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-inline: $space-40 $space-40;
    padding-block: $space-24 $space-24;
    gap: $space-16;

    @media (max-width: $viewport--max-width-ms) {
      padding-inline: 0;
    }
  }

  .confirm_button_pending {
    display: inline-flex;
    width: auto;
    padding: 0 $space-64 0 $space-64 !important;
  }

  .confirm_button_clicked {
    background-color: $status-winning-green;
    display: inline-flex;
    width: auto;
    padding: 0 $space-20 0 $space-20 !important;    

    .checkmark_icon {
      color: $white;
      display: flex;
      justify-content: flex-start;
      width: $space-24 !important;
      height: $space-24 !important;
      margin-right: $space-42;
    }
  
  }
  
  .no_result_text {
    text-align: center;
  }

  .disclaimer_text {
    text-align: center;
  }
}
