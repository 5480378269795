@import 'src/theme/variables';

.dialog_paper{
  margin: 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  min-height: 200px;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding-bottom: 1rem;
}

.item_icon {
  margin-right: $space-16;
}
