@import 'src/theme/variables';

.container {
  margin: -$space-16;
  margin-top: $space-8;
  padding: $space-16;

  @media (max-width: $viewport--max-width-md) {
    padding: $space-16 $space-16;
  }

  @media (min-width: $viewport--min-width-ms) {
    margin-inline: $space-48;
  }

  @media (min-width: $viewport--min-width-md) {
    margin-inline: calc($space-48 + 112px);
  }

  @media (min-width: $viewport--min-width-xl) {
    margin-inline: calc($space-48 + 222px);
  }
}