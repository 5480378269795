@import 'src/theme/variables';

.toolbar_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;

  @media (max-width: $viewport--max-width-ms) {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
  }
}

.header_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
}

.reset_button {
  font-size: 0.8125rem;
  color: $brand-orange;
  font-weight: $font-weight-7;
  white-space: nowrap;
  width: auto !important;
  height: auto !important;
  padding: $space-4 0 0 !important;
  margin-inline: $space-4;
}

.icon {
  margin-inline: $space-4;
}

.quick_filter {
  margin-left: auto;
  width: 280px;

  @media (max-width: $viewport--max-width-ms) {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
  }
}
