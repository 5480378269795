@import 'src/theme/variables';

.payment_list {
  margin: 10px;
  h1 {
    font-weight: 800;
    font-size: $font-size-18;
    margin-bottom: $space-16;
  }
}

.nav_icon {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
