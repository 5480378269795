@import 'src/theme/variables';

.back_button {
  margin-bottom: $space-24;
  cursor: pointer;
}
.container {
  margin: $space-16;

  @media (min-width: $viewport--min-width-ms) {
    margin-inline: $space-48;
  }

  @media (min-width: $viewport--min-width-md) {
    margin-inline: calc($space-48 + 112px);
  }

  @media (min-width: $viewport--min-width-xl) {
    margin-inline: calc($space-48 + 222px);
  }

}