@import 'src/theme/variables';

.menu_item_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: $space-8 0;

  .menu_item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: $space-16;
    width: 100%;
    padding: $space-8 $space-16;

    &:hover {
      cursor: pointer;
      background-color: $lightgrey-lightest;
    }
  }

  .menu_item_delete {
    @extend .menu_item;
    color: $sale-red;
  }
}