@import 'src/theme/variables';

.header {
  font-weight: 700;
  font-size: $font-size-24;
  margin-bottom: $space-24;
}

.link_back {
  margin-bottom: $space-24;
  width: 20%;

  button {
    padding-left: 0;
  }
}

.show_details_container {
  padding: $space-18;
  background: white;
  border-radius: $button-border-radius;
}

.show_unpublished_chip {
  margin: $space-12 0;
  line-height: $font--line-height-b1;
}
.auto_width {
  width: auto;
}