@import 'src/theme/variables';

.product_item {
  padding-top: 1rem;
  .image {
    position: relative;

    img {
      border-radius: 0.2rem;
    }

    .remove {
      position: absolute;
      right: -1rem;
      top: -1rem;

      button {
        width: $space-32;
        height: $space-32;
        padding: 0;
        background-color: $white;
        color: $black;

        &:hover {
          background-color: $white;
        }
      }
    }
    .refresh {
      position: absolute;
      right: -1rem;
      top: 2rem;

      button {
        width: $space-32;
        height: $space-32;
        padding: 0;
        background-color: $white;
        color: $black;

        &:hover {
          background-color: $white;
        }
      }
    }
  }

  .column {
    width: 8rem;
  }

  .row {
    margin-left: 1rem;
  }

  .substring {
    color: $iron;
    font-size: $font-size-14;

  }

  .product_name {
    hyphens: auto;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    overflow-wrap: break-word;
    color: $black ;
    font-size: $font-size-16;
    line-height: $space-24;
  }
}

.chip {
  position: absolute;
  left: 10px;
  top: 10px;
  border-radius: 4px;
  opacity: 1 !important;
  background-color: $granit-grey;
  width: $space-80;
  height: $space-24;
}

.saving_percent {
  position: absolute;
  top: $space-8;
  color: white;
  background-color: $sale-red;
  font-weight: 700;
  line-height: $space-24;
  padding-inline: $space-8;
}
