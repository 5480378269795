@import 'src/theme/variables';

.line {
  display: flex;
  justify-content: center;
  height: 2rem;
  div {
    width: 20%;
    height: 0.3rem;
    background-color: $grayLight;
    border-radius: 0.2rem;
  }
}
