@import 'src/theme/variables';

.divider {
  border-bottom: 2px solid $grayLight;
  width: 100%;
  margin-top: 12px;
}

.voucher_details_header {
  color: $attention-grey;
  margin-bottom: 1rem;
}

