@import 'node_modules/@hse24/shared-components/styles/colors';
@import 'node_modules/@hse24/shared-components/styles/spacings';
@import 'src/theme/variables';

.dialog_content {
  padding: 2.5rem 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: $viewport--min-width-ms) {
    height: 100vh;
    padding: 2.5rem 2rem;
  }

  .dialog_header {
    display: flex;
    justify-content: flex-end;
  }
  .dialog_body {
    margin-bottom: 6.37rem;
    .small_text {
      color: $iron;
    }
    .anchor{
      text-decoration: underline;
    }
  }
  .dialog_actions {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    .button {
      width: 100%;
      button {
        width: 100%;
      }
    }
  }
}


