@import 'src/theme/variables';

.content {
  display: flex;
  flex-direction: column;
  row-gap: $space-12;

  &.download {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.title {
  font-weight: 400;
}
