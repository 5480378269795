@import 'src/theme/variables';

.selected_post_media_preview {
  border-bottom: 2px solid $brand-orange;
}

.post_media_preview {
  position: relative;
  width: 120px;
  height: 160px;
  background-color: $white;
  flex-shrink: 0;
  cursor: pointer;

  .media {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .icon {
    height: 34px;
    width: 34px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    color: $white;
  }
}

.video_badge {
  background-color: $black;
  opacity: 0.5;
  border-radius: $space-10;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  gap: $space-4;
  padding-inline: $space-4 6px ;
  color: $white;
}
