@import "src/theme/variables";

.options {
  max-height: 280px;
}

.default_label {
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;

  div {
    display: flex;
    align-items: center;
    font-size: $font-size-16;
    line-height: 20px;
    color: $iron;
    font-weight: $font-weight-4;
  }
}
