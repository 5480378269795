@import 'src/theme/variables';

.start_streaming_menu_item {
  color: $brand-orange;
}

.delete_show_menu_item{
  color: $red;
}

.item_icon {
  margin-right: $space-16;
}
