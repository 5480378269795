@import '~@hse24/shared-components/styles/colors';
@import '~@hse24/shared-components/styles/spacings';
@import 'src/theme/variables';

$color--nightblue-lightest: #c1c7cf;

.button {
  display: flex;
  align-items: center;
  background-color: transparent;
  width: 100%;
  transition: color 0.15s, background-color 0.15s, border-color 0.15s;
  border: solid 2px transparent;
  outline: transparent;
  line-height: 1.4;
  -webkit-tap-highlight-color: transparent;

  .element {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $brand-orange;
  }

  .aside {
    flex: 0 0 auto;
  }

  .center {
    flex: 1 1 auto;
  }

  &.text-left .center {
    justify-content: flex-start;
  }

  // sizes
  &.tiny {
    $size: $space-32;
    // $size  => total height of the button
    // 0.25   => twice the size of the borders 2 x 2px = 4 / 16 = 0.25rem
    // 2      => divided by 2 as we want to keep the radius with the height
    $padding: (($size - 0.25) / 2);
    height: $size;

    &.dense-left {
      padding-left: $padding;
    }

    &.dense-right {
      padding-right: ($size - 0.25) / 2;
    }

    .aside {
      width: $size;
    }
  }

  &.small {
    $size: 2.5rem; // => 40px
    $padding: (($size - 0.25) / 2);
    height: $size;

    &.dense-left {
      padding-left: $padding;
    }

    &.dense-right {
      padding-right: ($size - 0.25) / 2;
    }

    .aside {
      width: $size;
    }
  }

  &.medium {
    $size: 3.625rem;
    $padding: (($size - 0.25) / 2);
    height: $size;
    width: 300px;
    font-weight: bold;
    justify-content: center;

    &.dense-left {
      padding-left: $padding;
    }

    &.dense-right {
      padding-right: ($size - 0.25) / 2;
    }

    .aside {
      width: $size;
    }
  }

  &.inline {
    display: inline-flex;
    width: auto;
  }

  &[aria-disabled='true'] {
    cursor: not-allowed;
  }
}

// types
.primary {
  background-color: $color--sale-red;
  color: $color--white;
}

.link {
  background-color: $color--white;
  color: $color--sale-red;

  &[aria-disabled='true'] {
    color: lighten($color--sale-red, 20);
    border-color: $color--lightgrey-lightest;
    background-color: $color--lightgrey-lightest;
  }
}

.blank {
  background-color: $color--pale-orange;
  color: $color--dark;
  border-radius: $button-border-radius;

  &[aria-disabled='true'] {
    color: $color--nightblue-lightest;
    border-color: $color--lightgrey-lightest;
  }
}
