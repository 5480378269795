@import 'src/theme/variables';

.menu_item {
  pointer-events: none;
}

.headline {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 8px;
}

.search_icon {
  color: $brand-orange;
  cursor: pointer;
}

.badge_container {
  margin-left: 6px;
  margin-top: 2px;
}

.badge_header_container {
  display: flex;
}

.products {
  // margin-bottom: $space-24;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .card {
    border: 1px solid $nightblue-lightest;
    width: 100%;
    height: 4.75rem;
    background: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .icon {
      color: $black;
    }
  }
  .card_error {
    border: 1px solid $venetian_red;
   @extend .card;

    .icon {
      color: $venetian_red;
    }
  }

  .horizontal_list {
    padding: 0 0 1rem 0;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    display: flex;
    gap: 1rem;
  }

  li {
    padding: 0;
  }
  .secondary_text {
    color: $iron;
  }
}
.error_product {
  @extend .products;
  margin-bottom: 0;
}

.product_button {
  width: fit-content;
  padding-inline: $space-16 !important;

  .aside_icon {
    margin-left: $space-10;
    width: $space-20;
    height: $space-20;
  }
}

.hide {
  display: none;
}
