@import 'src/theme/variables';

.wrapper {
  background-color: $color--white;
  width: 100%;
  max-width: $viewport--max-width-md;
  margin: auto;
  font-family: Lato, sans-serif;
  font-size: $font-size-16;
  line-height: $font-size-24;

  div {
    margin-bottom: $space-12;
  }

  h1 {
    font-size: $font-size-28;
    font-weight: 500;
    margin-bottom: $space-18;
  }

  p {
    margin: $space-16 auto;
  }

  .link {
    text-decoration: underline;
    color: $primary;
    display: inline;
  }
}
