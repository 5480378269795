@import 'src/theme/variables';


.container {
  margin-top: 1rem;

  .image_selection {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .image_zone {
    border: 1px solid $nightblue-lightest;
    border-radius: .5rem;
    aspect-ratio: 1/1;
    width: 100%;
    background: $white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .image_box {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;

      img {
        aspect-ratio: 1/1;
        border-radius: .5rem;

      }
    }

    .buttons {
      position: absolute;
      transform: translate(-50%, 0);
      left: 50%;
      bottom: 1rem;
      display: flex;
      gap: 1rem;

      .input {
        display: none;
      }

      .icon_button {
        padding: 1rem;
        border-radius: 2rem;

        &_delete {
          background: $white;
        }
      }

      &.hide {
        display: none;
      }
    }

    &.disabled {
      cursor: not-allowed;
    }
  }


  .post_details {
    display: flex;
    flex-direction: column;

    .caption {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .field {
        width: 100%;
        background: $white;
      }
    }

    .actions {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;

      button {
        width: 100%;
        font-size: 1rem;
        padding: 6px 12px;
        border-radius: .3rem;
        line-height: 1.5;
        height: 3rem;
      }
    }

    .products {
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .card {
        border: 1px solid $nightblue-lightest;
        width: 100%;
        height: 4.75rem;
        background: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .horizontal_list {
        padding: 0 0 1rem 0;
        overflow-x: scroll;
        overflow-y: hidden;
        width: 100%;
        display: flex;
        gap: 1rem;
      }
    }
  }
}

.secondary_text {
  color: $iron;
}

.schedule_at {
  margin-bottom: $space-16;
  display: flex;
  flex-direction: column;
  gap: $space-16;
}
