@import '~@hse24/shared-components/styles/colors';
@import '~@hse24/shared-components/styles/spacings';
@import '~@hse24/shared-components/styles/breakpoints';
@import 'src/theme/variables';

.unlocked {
  opacity: 0.6;
}
.hero_insights {
  width: 100%;
  background-color: $white;
  border-radius: .5rem;
  height: 100%;
  padding: 20px;

  &.current{
    border: 1px solid $solitude;
  }

  .hero_title {
    display: flex;
    align-items: center;
    padding: 20px;

    h1 {
      font-size: 22px;
      font-weight: 900;
      margin-right: 5px;
    }

    span {
      font-size: 14px;
      font-weight: 400;
    }

    @media (max-width: $viewport--max-width-ms) {
      padding-left: 20px;
    }
  }
  .overview {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1.2fr 0.2fr 0.9fr;
    column-gap: 10px;

    .insights_data {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-left: 10px;
    }

    .divider {
      height: 60%;
      width: 1px;
      @media (max-width: $viewport--max-width-ms) {
        width: 100%;
        height: 1px;
      }

      @media (max-width: $viewport--max-width-s) {
        display: block;
        width: 100%;
        height: 1px;
      }
    }

    .hints {
      .hint_list {
        list-style: initial;
        margin-left: 15px;
        margin-top: 20px;
        li {
          margin-bottom: 5px;
          font-weight: 700;
        }
      }

      .hint_title {
        background: linear-gradient(to right, $office-neon-light 0%, $brand-orange 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 700;
        font-size: 14px;
      }
    }

    .unlocked_progress {
      position: relative;
      .half_circle {
        border: 9px solid $texas-sunset;
        opacity: 0.1;
        border-radius: 50%;
        border-bottom-color: transparent;
        border-left-color: transparent;
        width: 178px;
        height: 178px;
        position: absolute;
        transform: translate(-50%, -50%) rotate(-45deg);
        left: 50%;
        top: 50%;
      }
      .circle_icon {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        border-radius: 50%;
        width: 54px;
        height: 54px;
        background-color: $light-quilt;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          color: $curry;
        }
      }
    }

    .spinner_container {
      width: 250px;
      height: 250px;
      margin: auto;

      @media (max-width: $viewport--max-width-s) {
        width: 200px;
        height: 200px;
      }
    }
    @media (max-width: $viewport--max-width-ms) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      row-gap: 10px;
    }

    @media (max-width: $viewport--max-width-s) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }
  }
  .commission_link {
    font-size: 12px;
    padding: 20px;
    font-weight: 400;
    cursor: pointer;
    color: rgba(13, 31, 60, 0.67);

    @media (max-width: $viewport--max-width-ms) {
      padding-left: 10px;
    }
  }
  .hide_viewers {
    display: none;
  }
}

.collapse_container {
  background: $white;
  margin-top: 1rem;
  border-radius: .5rem;
  border: 1px solid $solitude;

  .collapse_header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    cursor: pointer;
  }
}