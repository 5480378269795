@import 'src/theme/variables';

.product_slider {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;

  .product_tile {
    position: relative;
    display: inline-flex;
    object-fit: cover;
    margin: $space-8 calc($space-8 / 2);
    width: $space-64;
    height: $space-80;

    .label {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 3px 2px;
      color: $white;
      font-size: 10px;
      font-weight: $font-weight-7;
      line-height: $space-10;
      text-align: center;
      text-transform: uppercase;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .sold_out_label {
      @extend .label;
      background-color: $night-blue-nightblue-2-mid;
    }

    .voucher_label {
      @extend .label;
      background-color: $status-winning-green;
    }

    .regular_discount_label {
      @extend .label;
      background-color: $color--error-red;
    }

    .image_overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;

      &.dark_layer {
        background-color: rgba(0, 0, 0, 0.5019607843);
      }

    }

    @media (min-width: $viewport--min-width-ms) {
      &.large {
        width: $space-80;
        height: 100px;
      }
    }
  }

  .product_tile:first-child {
    margin-left: $space-8;

    @media (min-width: $viewport--min-width-ms) {
      margin-left: 0;
    }
  }

  .product_tile:last-child {
    margin-right: $space-8;
  }
}


