@import 'src/theme/variables';

.appbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: $white-lilac;
  min-height: calc(#{$navbar--height} + env(safe-area-inset-top));

  @supports (padding-top: env(safe-area-inset-top)) {
    padding-top: env(safe-area-inset-top);
  }
}

.toolbar {
  min-height: $navbar--height;
  padding-left: $space-24;
  padding-right: $space-24;
}

.title {
  padding: 0;
  margin: 0.5rem 0.75rem;
  font-family: Lato;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;


  @media (max-width: $viewport--max-width-md) {
    margin-left: 0;
  }
}
