@import 'src/theme/variables';

.modal {
  width: 750px;
  padding-bottom: $space-24;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: $space-24;
  padding: $space-16 $space-48;
  background-color: $white;
  @media (max-width: 564px) {
    display: flex;
    flex-direction: column;
  }
}

.actions{
  display: flex;
  justify-content: center;

  .close {
    width: 46%;
  }
}