@import '~@hse24/shared-components/styles/colors';

.loading {
  background-color: transparent;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
