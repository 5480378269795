@import 'src/theme/variables';

.menu_stack {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: $space-8 0;

  .menu_item {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding: $space-8 $space-16;
    &:hover {
      cursor: pointer;
      background-color: $lightgrey-lightest;
    }

    svg,
    div:first-of-type {
      height: 24px !important;
      width: 24px !important;
    }

    svg{
      justify-self: flex-end;
    }

    .check_mark {
      color: $color--winning;
    }

    .menu_item_text {
      margin-left: $space-16;
      font-size: $font-size-16;
      line-height: 24px;
    }
  }
}
