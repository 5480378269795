@import 'src/theme/variables';

.header {
  font-weight: 700;
  font-size: $font-size-24;
  margin-bottom: $space-24;
}

.link_back {
  margin-bottom: $space-24;
  width: 20%;

  button {
    padding-left: 0;
  }
}

.show_details_container {
  display: flex;
  flex-direction: column;
  gap: $space-48;
  padding: $space-48;
  margin-top: $space-24;
  background: white;

  .show_details {
    display: flex;
    flex-direction: column;
    gap: $space-32;

    .row {
      display: flex;
      flex-direction: column;
      gap: $space-48;

      > * {
        flex: 1 0 0;
      }

      @media (min-width: $viewport--min-width-md) {
        flex-direction: row;
      }
    }

    .external_info {
      display: flex;
      flex-direction: column;
      gap: $space-24;
    }

    button {
      width: fit-content;
    }
  }
}

.show_unpublished_chip {
  margin: $space-12 0;
  line-height: $font--line-height-b1;
}

.action_button {
  width: fit-content;
  padding-inline: $space-24 !important;

  .aside_icon {
    margin-left: $space-10;
    width: $space-20;
    height: $space-20;
  }
}
