@import 'src/theme/variables';

.container {
  background: rgba(58, 62, 69, 1);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;

  .content {
    max-width: 57rem;
    margin: auto;
  }
}