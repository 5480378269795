@import 'src/theme/variables';
.feedback {
  display: grid;
  grid-template-columns: 3fr 1fr;
  .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    width: 100%;
  }
}
.showMore {
  color: $brand-orange;
}
