@import 'src/theme/variables';

.recaptcha {
  display: block;
  font-family: Lato, sans-serif;
  font-weight: $font-weight-4;
  font-size: $font-size-13;
  letter-spacing: $letter-spacing-1;
  line-height: $font--line-height-b1;
  text-align: center;
  margin-top: $space-16;
  margin-bottom: $space-16;
  color: $iron;

  a {
    display: inline;
    text-underline-offset: $underline-offset-link;
    text-decoration-thickness: from-font;
  }

  @media (max-width: $viewport--min-width-md) {
    max-width: $form--width;
    font-size: $font-size-12;

    @media (max-device-height: 458px) {
      max-width: 260px;
    }
  }
}
