@import 'src/theme/variables';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: $space-16;
  background-color: $white;
  width: 100%;
  border-radius: $space-8;
  animation: shimmer 1.5s infinite linear;
  background-size: 800px 104px;
}

.topSection {
  display: flex;
  align-items: center;
  margin-bottom: $space-14;

  .leftPart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: $space-80;
    border-radius: $space-6;
    min-width: 0;

    .valueSkeleton {
      width: $space-60;
      height: $space-32;
      background-color: $nightblue-lightest;
      border-radius: $space-4;
      margin-bottom: $space-6;
    }

    .valueSmallSkeleton {
      width: $space-40;
      height: $space-10;
      background-color: $nightblue-lightest;
      border-radius: $space-4;
    }
  }

  .rightPart {
    width: 60%;
    margin-left: $space-14;

    .descriptionSkeleton {
      width: 80%;
      height: $space-14;
      background-color: $nightblue-lightest;
      border-radius: $space-4;
    }
  }
}

.middleDivider {
  width: 100%;
  height: 1px;
  background-color: $nightblue-lightest;
  margin-block: $space-14 $space-14;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;

  .buttonSkeleton {
    width: 100%;
    height: $space-40;
    background-color: $nightblue-lightest;
    border-radius: $space-4;
  }
}

@keyframes shimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}
