@import 'src/theme/variables';

.buttons {
  display: flex;
  align-items: center;

  .btn {
    flex-grow: 4;

    .disabled {
      background: #E0E0E0;
      color: #00000042;
    }
  }

  .card_menu {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.title {
  font-weight: 400;
}

.three_dots_icon {
  color: white;
}

.multiLineEllipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
