@import 'src/theme/variables';

.chat_log {
  padding-right: 5rem;

  @media (max-width: $viewport--max-width-ms) {
    padding-right: 3.5rem;
  }
}

.chat_last_joiner {
  font-size: 0.813rem;
  color: #ffffff;
}

.chat_display_button {
  width: fit-content;
  height: 1.5rem !important;
  padding: 0 !important;
  background: transparent;
  border: none;
  color: #ffffff;
  white-space: nowrap;
  transition: all 1s ease-in-out;

  &:hover {
    background: transparent;
  }

  span {
    width: auto !important;
    display: inline;
    font-size: 0.875rem;
    line-height: 1.5rem;
    text-align: center;
  }
}
