@import 'src/theme/variables';

.caption {
  font-weight: $font-weight-7;
  margin-bottom: $space-16;
  font-size: $font-size-18;
}

.resendWelcomeMailBtn{
  font-weight: bold;
}
