@import 'src/theme/variables';

.overview {
  height: auto;
  width: 100%;
}

.card {
  height: 100%;
  min-height: 200px;
  padding-top: 5px;
  box-shadow: none;
  background-color: transparent;
}

.white_card {
  border: 0.06rem solid $lightgreyMid;
  border-radius: 10px;
  padding: 20px;
  background-color: $white;
}

.backlink {
  margin-right: $space-16;
}

.box {
  margin-bottom: $space-24;

  button {
    padding-left: 0;
  }
}
