@import 'src/theme/variables';

.text_field {
  position: relative;

  input{
    color: $black;
    font-family: Lato, sans-serif;
  }

  .char_count {
    position: absolute;
    bottom: 0;
    right: 0;
    color: #595964;
    text-align: right;
    font-size: $font-size-13;
    line-height: 20px;
    letter-spacing: 0.13px;
  }
}
