@import 'src/theme/variables';

.dialog_paper {
  height: calc(100% - 64px);
  border-radius: 1rem;

  @supports (padding-top: env(safe-area-inset-top)) {
    padding-top: env(safe-area-inset-top, $space-18);
  }
}

.content {
  padding: $space-16;
  display: flex;
  flex-direction: column;
  gap: $space-16;
  height: 100vh;
  overflow: hidden;
}

.button {
  margin: $space-16;

  button {
    padding: $space-8;
  }
}

.add_btn {
  width: $space-32;
  height: $space-32;
  border-radius: 50%;
  display: flex;
  justify-content: flex-start;
  padding: 0 0 !important;
}

.add_icon {
  width: $space-18;
  height: $space-18;
}

.checked_btn {
  width: $space-32;
  height: $space-32;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: flex-start;
  padding: 0 0 !important;
  background-color: #2e7d32;
  &:hover {
    background-color: #2e7d32 !important;
  }
}

.checked_icon {
  width: 100%;
}

.icon {
  width: $space-18;
}

.products {
  flex: 1;
  overflow-y: auto;

  .product {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $lightgrey-mid;
    padding-bottom: $space-16;
    cursor: pointer;
  }
  .disabled_product {
    cursor: default !important;
  }
}

.actions {
  display: flex;
  justify-content: space-evenly;
  gap: $space-14;
  width: 45.75rem;
  position: sticky;
  bottom: 0;
  background-color: white;
}

@media (max-width: $viewport--max-width-ms) {
  .actions {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.extra_space {
  width: $space-48;
  height: 6rem;
  display: flex;
  align-items: center;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $space-48;
}
