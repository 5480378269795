@import 'src/theme/variables';

.symbol_wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $navbar--width;

  .symbol {
    width: 100%;
    object-fit: contain;
    transition: opacity 0.15s ease-in-out;
    will-change: opacity;
    &:hover {
      opacity: 0.8;
    }
  }
}
