@import 'src/theme/variables';

.menu_item {
  background-color: $white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.3;
}