@import "src/theme/variables";

.time_zone {
  color: $black;
  opacity: 0.6;
  stroke-width: 1;
  stroke: $white;
  display: flex;
  justify-items: center;
  margin: $space-12 0 0;
  gap: calc($space-8 / 4);

  p {
    font-size: $font-size-14;
    font-weight: $font-weight-4;
    line-height: $space-24;
  }
}
