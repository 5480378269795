@import 'src/theme/variables';

.modal {
  border-radius: 0;
  width: 100%;

  @media (min-width: $viewport--min-width-md) {
    border-radius: $space-8;
    width: 612px;
  }
}

.content {
  padding: $space-16 $space-24;
  background: $white-lilac;

  @media (min-width: $viewport--min-width-md) {
    background-color: $white;
  }

  .date_data {
    padding: $space-12 $space-12;
    background-color: $white;
    border-radius: 8px 8px 0 0;
  }

  p {
    font-size: $font-size-16;
    line-height: $space-24;
  }

  .actions {
    position: unset;
  }
}
