@import "src/theme/variables";

.creator_option {
  display: flex;
  align-items: center;
  gap: $space-8;

  .creator_image {
    img {
      max-width: 40px;
      max-height: 40px;
      border-radius: 50%;
    }
  }

  .name {
    font-family: Lato, sans-serif;
    font-weight: $font-weight-4;
    font-size: $font-size-16;
  }
}




