@import '~@hse24/shared-components/styles/colors';

.searchLoading {
  background-color: $color--white;
  min-height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
