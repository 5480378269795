@import 'node_modules/@hse24/shared-components/styles/colors';
@import 'node_modules/@hse24/shared-components/styles/spacings';
@import 'src/theme/variables';

footer {
  background-color: $white;
  align-items: center;
  justify-content: center;
  padding: $space-16;
  width: 100%;

  .footerLink {
    color: $black;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .footerIcon {
    margin-right: $space-8;
  }
}
