@import 'src/theme/variables';

.profile_dashboard {
  margin: -$space-16;
  padding: 16px;

  @media (max-width: $viewport--max-width-md) {
    padding: $space-16 $space-16;
  }

  @media (min-width: $viewport--min-width-ms) {
    margin-inline: $space-48;
  }

  @media (min-width: $viewport--min-width-md) {
    margin-inline: calc($space-48 + 112px);
  }

  @media (min-width: $viewport--min-width-xl) {
    margin-inline: calc($space-48 + 222px);
  }

  .top_container {
    display: flex;
    flex-direction: column;
    padding: $space-16 $space-24 $space-32;
    justify-content: center;
    align-items: center;
    gap: $space-24;
    width: 100%;

    @media (min-width: $viewport--min-width-ms) {
      flex-direction: row;
      padding: $space-16 0 $space-32 0;
    }
  }

  section {
    display: flex;
    flex-direction: column;
    padding: $space-16 0;
    gap: $space-16;

    @media (min-width: $viewport--min-width-ms) {
      padding-inline: 0;
      padding: $space-32 $space-24;
    }
  }

  .links {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: $space-16;
    align-self: stretch;
    flex-wrap: wrap;

    .link {
      display: flex;
      height: 80px;
      max-width: 208px;
      min-width: 120px;
      padding: $space-24 0;
      justify-content: center;
      align-items: center;
      gap: $space-4;
      flex: 1 0 0;
      border-radius: $space-4;
      background-color: $white;
      cursor: pointer;

      &:hover {
        background-color: $lightgrey-mid;
      }
    }

    .image_link {
      @extend .link;
      background-color: unset;
      max-width: fit-content;
      position: relative;

      img {
        border-radius: inherit;
        height: inherit;
        max-width: 100%;
        object-fit: cover;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.33) 100%);
        z-index: 1;
        border-radius: $space-4;
      }

      .label {
        position: absolute;
        bottom: $space-8;
        left: $space-8;
        color: $white;
        font-size: $font-size-16;
        line-height: 24px;
        z-index: 2;
      }
    }

    .badge {
      font-weight: $font-weight-7;
      width: 18px;
      height: 18px;
      margin-left: 4px;
      border-radius: 50%;
      background-color: $brand-orange;
      color: $white;
      text-align: center;
    }
  }

  .budget_section {
    display: flex;
    justify-content: space-between;

    .badge {
      display: flex;
      align-items: center;
      text-align: center;

      .skeleton {
        min-width: 115px;
        height: $space-24;
      }

      .text {
        font-weight: $font-weight-7;
      }
    }
  }
}

.profile {
  display: flex;
  align-items: center;
  gap: $space-16;
  align-self: stretch;
  cursor: pointer;
  flex: 1 0 0;

  .profile_pic {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid $white;
  }

  .profile_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;

    .edit_button {
      color: $iron;
      border-bottom: 1px solid $iron;
      cursor: pointer;
      transition: color 0.3s ease;
    }
  }

  .arrow_icon {
    @media (min-width: $viewport--min-width-ms) {
      display: none;
    }
  }

  @media (min-width: $viewport--min-width-ms) {
    padding-inline: 0;
  }
}

.profile:hover .edit_button {
  color: $brand-orange-hover;
  border-bottom: 1px solid $brand-orange-hover;
}

.linkBtn {
  padding-block: $space-12 4px;
  cursor: pointer;
  color: $iron;
}

.logout {
  @extend .linkBtn;
  padding-block: 4px $space-12;
  color: $sale-red;
}
