@import 'src/theme/variables';
@import '~@hse24/shared-components/styles/breakpoints';

.main {
  background-color: $lightgrey-lightest;
}

.container {
  width: 100%;
  height: 100%;
  background-color: $lightgrey-lightest;
  margin: auto;
  max-width: 90rem;

  .divider {
    background-color: $light-grey;
  }

  .commission_table_wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    max-width: 90rem;
    margin: 0 auto;
    padding: 0.625rem 0;
    align-items: center;
    justify-content: flex-end;

      @media (max-width: $viewport--max-width-ms) {
        flex-direction: column;

        h1 {
          text-align: left;
          margin: $space-12 0;
        }

        .month_picker {
          margin: 0.625rem 0;
        }
      }

    .commission {
      align-items: flex-start;
      opacity: 67%;

      a {
        font-weight: 400;
        font-size: $space-12;
        padding-bottom: 0.125rem;
        border-bottom: 0.06rem solid $black;
        cursor: pointer;
      }

      @media (max-width: $viewport--max-width-ms) {
        align-self: flex-end;
      }
    }

    @media (max-width: $viewport--max-width-ms) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }

  .charts_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 90rem;
    margin: 0 auto;
    padding: 2.5rem 0;

    > div {
      flex: 1;
    }

    > div:first-child {
      margin-right: $space-24;
    }

    @media (max-width: $viewport--max-width-ms) {
      flex-direction: column;
      > div:first-child {
        margin: $space-16 0;
      }
      padding: 0;
      margin-top: $space-24;
    }
  }
}
