@import 'src/theme/variables';
@import '~@hse24/shared-components/styles/breakpoints';

.container {
  width: 100%;
  min-height: 100vh;
  background-color: $white-lilac;

  .navigation {
    display: flex;
    flex-direction: column;
    gap: $space-24;
    margin-bottom: $space-24;

    .link {
      display: flex;
      align-items: center;
    }

    button {
      padding-left: 0;
    }

    h1 {
      font-size: 24px;
      font-weight: 900;
    }
  }

  .table_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: $space-32;

    .search_input {
      background-color: white;
    }

    h1 {
      align-self: flex-end;
      font-size: 24px;
      font-weight: 900;

      @media (max-width: $viewport--max-width-ms) {
        align-self: flex-start;
        margin-bottom: 10px;
      }
    }

    @media (max-width: $viewport--max-width-ms) {
      flex-direction: column;
    }
  }
}
