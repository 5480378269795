@import "src/theme/variables";

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: $lightgrey-lightest;
  width: 300px;
  border-radius: $space-8;
  gap: $space-8;
  padding: $space-8;

  .upload_status {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-left: auto;
  }

  .file_icon{
    border-radius: 50%;
    background-color: #ffffff;
    width: $space-24;
    height: $space-24;
    justify-content: center;
    display: flex;
    align-items: center;
    align-self: center;
  }

  .file_description  {
    font-weight: $font-weight-4;
    .file_name {
      font-size: $font-size-12;
      line-height: 15px;
    }
    .file_size, .unsupported_file_format {
      font-size: 10px;
      line-height: $space-12;
      color: $iron;
    }
  }
}