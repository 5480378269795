@import 'src/theme/variables';

.planned_shows_container {
  display: flex;
  flex-direction: column;
  row-gap: $space-24;

  @media (min-width: $viewport--min-width-ms) {
    align-items: center;
  }

  .welcome_text + .no_show_text {
    margin-top: -$space-12;
  }
}

.no_show_text {
  width: 100%;
  text-align: start;
  color: $iron;
  line-height: 24px;
  font-size: $font-size-16;
}

.welcome_text {
  width: 100%;
  line-height: 28px;
  font-size: $space-20;
  font-weight: $font-weight-4;
  font-style: normal;
  text-align: start;

  @media (max-width: $viewport--max-width-md) {
    line-height: $space-24;
    font-size: $space-20;
    font-weight: $font-weight-4;
    font-family: Lato;
    text-align: left; 
  }
} 

.no_upcoming_show_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: $space-72;
}

.create_show_button {
  padding: $space-8 $space-16;
  width: min(315px, 100%);
  color: $black;
  background-color: $white;
  border: 2px solid $color-light-grey;
  border-radius: 0;

  &:hover {
    background-color: initial;
    border: 2px solid $color-light-grey;
  }
}

.no_show_image {
  max-width: 245px;
  margin-bottom: $space-16;
}

.add_stream_question {
  margin-bottom: $space-16;
}
