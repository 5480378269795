@import 'src/theme/variables';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: $viewport--max-width-md) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.title {
  font-size: $font-size-36;
  line-height: $font--line-height-40;
  font-weight: $font-weight-7;

  @media (max-width: $viewport--max-width-md) {
    display: none;
  }
}

.showOnMobile {
  @media (max-width: $viewport--max-width-md) {
    display: block;
  }
}

.buttonContainer {
  display: flex;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: $font-weight-7;
  font-size: $font-size-16;
  height: $space-42 !important;

  @media (max-width: $viewport--max-width-md) {
    align-self: flex-start;
    line-height: 20px;
  }
}

.iconText {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: $font-weight-7;
  gap: $space-10;
}
