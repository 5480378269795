@import 'src/theme/variables';

.navigator {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
}

.arrow_back {
  display: flex;
  align-items: center;
  .arrow_back_icon {
    margin-right: 10px;
  }
  .arrow_back_text {
    font-weight: bold;
  }
}

.text {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
}

.text_right{
  text-align: right;
}

.text_row {
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  margin-top: 20px;
  .text_content {
    align-self: flex-end;
    max-width: 200px;
  }
}

.title {
  margin-top: 40px;
  .text_title {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.01em;
  }
  .text_title_light {
    font-size: 12px;
  }
}

.list {
  margin: 0;
}

.chip {
  background: $color--pale-orange;
  color: $brand-orange;
}

.divider {
  margin: 1rem 0;
}
