@import 'src/theme/variables';


.wrapper{
  display:grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;

  .item{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    cursor: pointer;

    .card{
      padding: 1.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $snow-flake;
      border-radius: .5rem;

      &:hover{
        background-color: $nightblue-lightest;
      }

      svg{
        width: 25px;
        height: 25px;
      }
    }
  }
}