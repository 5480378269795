@import 'src/theme/variables';

.preview_image {
  aspect-ratio: 16/9;
  background: $light-grey;
  border-radius: .25rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.videos {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1.5rem 1rem 1rem;
}