@import 'src/theme/variables';

.products_list {
  width: 300px;
  max-height: 600px;
  height: 100%;
  overflow-y: scroll;
  direction: rtl;
  li {
    direction: ltr;
  }
}

.left_right_indicator{
  max-height: 600px;
  height: 100%;
  color: $color--grey;
}

.hightlighed_list {
  width: 300px;
  max-height: 600px;
  height: 100%;
  overflow: hidden;
}

.placeholder_highlighted {
  width: 100%;
  height: 124px;
  border: 1px dotted $color--grey;
}