@import '~@hse24/shared-components/index';
@import 'src/theme/variables';

.box {
  max-width: 555px;
  margin: 20% $grid--offset-s 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $brand-orange;
}

.info {
  color: $color--iron;
  font-size: 16px;
  text-align: center;
}

.icon_face {
  font-size: 4rem;
}
