@import 'src/theme/variables';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 428.75px;
  background-color: $black;

  @media (min-width: $viewport--min-width-md) {
    height: 666px;
  }

  .media {
    max-width: 100%;
    max-height: 100%;
  }

  .menu {
    position: absolute;
    bottom: 0;
    margin-bottom: $space-8;
    margin-right: $space-8;
    right: 0;
    display: flex;
    gap: $space-8;
    z-index: 1;
  }
}

.trash_icon {
  color: $sale-red;
}

.icon_up {
  transform: rotate(-90deg);
}

.icon_down {
  transform: rotate(90deg);
}
