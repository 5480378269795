@import 'src/theme/variables';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.statistic {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label {
  font-size: $font-size-14;
  font-weight: $font-weight-4;
  color: $iron;
}

.count {
  font-size: $font-size-18;
  font-weight: $font-weight-7;
  color: $black;
}

.vertical_line {
  height: 18px;
  width: 1px;
  background-color: $black;
  margin: 0 $space-24;
}

