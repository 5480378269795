@import 'src/theme/variables';

.submit_button_wrapper {
  display: flex;
  margin-top: 20px;

  button {
    width: 100%;
  }
}

.streamer_images_form {
  width: 100%;
}

.accordion_details {
  display: flex;
  justify-content: space-around;
}
.secondary_text {
  margin-top: $space-16;
  color: $iron;
}
