@import 'src/theme/variables';
@import '~@hse24/shared-components/styles/breakpoints';

.cards_container {
  display: flex;
  margin-top: 30px;
  overflow-x: auto;

  .card {
    min-width: 250px;
    margin-right: 24px;

    @media (max-width: $viewport--max-width-ms) {
      margin-right: 0px;
    }
  }

  .toolTip_container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    span {
      margin-right: 5px;
    }

    svg {
      opacity: 0.2;
      cursor: pointer;
    }
  }
  @media (max-width: $viewport--max-width-ms) {
    flex-direction: column;

    .card:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

.modal {
  background-color: $lightgrey-lightest !important;
  border-radius: 10px !important;
  width: auto !important;
}
