@import 'src/theme/variables';

.table_wrapper {
  h1 {
    font-weight: 900;
    font-size: $space-24;
  }

  .toggle {
    margin: $space-32 0;
    display: flex;
    align-items: center;
  }

  .last_column {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.ama_badge {
  background: rgba(246, 231, 255, 1);
  padding: .2rem .4rem;
  border-radius: .2rem;
  cursor: pointer;
}
