@import 'src/theme/variables';

.back_button {
  margin-top: $space-12;
  margin-bottom: $space-8;
}
.exit {

  display: flex;
  align-items: center;
  gap: $space-12;
  cursor: pointer;

  .text {
    font-size: $font-size-16;
    font-weight: $font-weight-4;
    line-height: $space-20;
    letter-spacing: 0.14px;
  }

  button {
    padding: 0;
    color: $black;
  }
}