@import 'src/theme/variables';

.icon {
  width: 3rem;
  height: 3rem;
  background: linear-gradient(120.34deg, #FF257F 27.65%, $brand-orange 75.99%),
  linear-gradient(0deg, #FFFFFF, #FFFFFF);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: $white;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}