@import 'src/theme/variables';

.container {
  .label {
    padding: $space-8;
    color: $iron;
  }

  .new_status {
    width: 100%;
    display: flex;
    align-items: center;
    gap: $space-16;
    padding: $space-8 $space-16;
    cursor: pointer;
    font-size: $font-size-16;

    &:hover {
      background: $lightgrey-lightest;
    }

  }

  .new_status_disabled {
    @extend .new_status;
    cursor: not-allowed;

    &:hover {
      background: unset;
    }
  }

  .published {
    color: $status-winning-green;
  }

}

