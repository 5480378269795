@import "src/theme/variables";

.custom_timer_container {
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-wrap: wrap;

  button {
    font-size: $font-size-16;
    font-weight: $font-weight-4;
    letter-spacing: 0.15px;
    padding: 5px;
  }
}
