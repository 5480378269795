@import 'src/theme/variables';

.wrapper {
  position: relative;

  .close_icon {
    position: absolute;
    top: $space-12;
    right: $space-16;
    cursor: pointer;
  }
}

.container {
  width: fit-content;
  position: relative;
}

.toolTip_wrapper {
  h1 {
    font-size: $space-16;
    font-weight: 700;
  }

  p {
    color: $black;
    font-size: 14px;
    font-weight: 400;
    margin: 16px 0;
  }

  u {
    cursor: pointer;
  }

  .icon {
    position: absolute;
    right: 10px;
    top: 16px;
    cursor: pointer;
  }
}

