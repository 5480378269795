@import '~@hse24/shared-components/styles/colors';
@import '~@hse24/shared-components/styles/spacings';
@import '~@hse24/shared-components/styles/breakpoints';
@import 'src/theme/variables';

.container {
  width: 100%;
  background-color: $white-lilac;
  margin-bottom: 30px;
}
