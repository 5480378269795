@import 'src/theme/variables';

.table_filter {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  gap: 1rem;
  margin-top: $space-32;

  @media (max-width: $viewport--max-width-md) {
    margin-top: $space-20;
  }
}

.link {
  color: $brand-orange;
}

.toggle {
  display: flex;
  align-items: center;
}
.filter_heading{
  font-weight: 800;
  font-size: 18px;
}