@import "src/theme/variables";

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  height: 75%;
  width: 100%;

  .current_day p {
    padding: $space-32;
  }
}

.actions  {
  padding: $space-16;
}
