@import 'src/theme/variables';
.main {
  width: 100%;
  background-color: $white-lilac;
}

.container {
  margin: auto;
}

.card {
  background-color: $white;
  border: 1px solid #e9ecf2;
  padding: 40px 24px;
  box-sizing: border-box;
  border-radius: 12px;
}
.export_pdf_mobile{
  margin-bottom: 20px;
}

.streamer_details{
  @media (max-width: 958px){
    display: none;
  }
}

.export_pdf {
  width: max-content;
  padding: 12px 24px;
  margin-top: 17px;
  margin-left: auto;
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: center;
  border: 1px solid $black;
  box-sizing: border-box;
  border-radius: 4px;

  svg {
    margin-right: 10px;
  }
}

.export_pdf_mobile{
  @media (min-width: 958px){
    display: none;
  }
}
