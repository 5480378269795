@import 'node_modules/@hse24/shared-components/styles/colors';
@import 'node_modules/@hse24/shared-components/styles/spacings';
@import 'src/theme/variables';

.dialog_content {
  padding: 2.5rem 3.5rem;
  display: flex;
  flex-direction: column;
  @media (max-width: $viewport--min-width-ms) {
    height: 100vh;
    padding: 2.5rem 2rem;
  }

  .dialog_header {
    display: flex;
    justify-content: flex-end;
  }

  .dialog_body{
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}