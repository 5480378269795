@import 'src/theme/variables';

$table_filters_width: 400px;

.container {
  margin-top: $space-32;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  gap: $space-24;
  position: relative;
  width: 100%;

  @media (max-width: $viewport--max-width-md) {
    flex-direction: column;
    max-width: 100%;
    overflow-x: hidden;
    margin-top: $space-20;
  }
}

.table_filter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: $space-32;
  position: -webkit-sticky;
  position: sticky;
  top: $space-72;
  height: 100%;

  min-width: $table_filters_width;
  max-width: $table_filters_width;

  @media (max-width: $viewport--max-width-md) {
    position: unset;
    min-width: unset;
    max-width: unset;
    width: 100%;
    margin-bottom: $space-16;
  }
}

.quick_filters_label {
  font-weight: 700;
  font-size: $font-size-20;
  line-height: 24px;
  margin-bottom: $space-16;
}

.quick_filters_wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
}

.quick_filters_button {
  width: fit-content;
  padding: 0 $space-14 !important;
}

.shows_wrapper {
  flex-grow: 1;
  max-width: calc(100% - $table_filters_width - $space-24);
  @media (max-width: $viewport--max-width-md) {
    max-width: 100%;
  }
}

