@import 'src/theme/variables';

.appbar {
  border-bottom: solid 1px $grayLight;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: $white;
  min-height: $navbar--height;
  display: flex;
  align-items: center;
  padding: 0 .5rem;

  @supports (padding-top: env(safe-area-inset-top)) {
    padding-top: env(safe-area-inset-top);
  }
}

.toolbar {
  min-height: $navbar--height;
  width: 100%;
}

.header {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  width: 100%;

  .title {
    overflow: hidden;
    text-align: center;

    h6 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      width: 100%;
    }
  }
}
