@import 'src/theme/variables';

.header {
  margin-bottom: $space-24;
}

.modal_title {
  display: flex;
  align-items: center;
  column-gap: $space-8;
}
.button{
  width: auto;
}