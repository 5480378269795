@import 'src/theme/variables';

.container {
  flex: 0 0 50%;
  border-radius: 0.5rem;
  cursor: pointer;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%),
    linear-gradient(120deg, #ff257f 27.65%,$brand-orange 75.99%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem 0.5rem;

  .ama_picture {
    width: 195px;
    height: 102px;
    margin-bottom: 1rem;
  }
}

.circle {
  width: 3.5rem;
  height: 3.5rem;
  border: 1px solid $white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: $space-16;

  @media (max-width: $viewport--max-width-ms) {
    width: 3.75rem;
    height: 3.75rem;
  }

  svg {
    width: 2rem;
    height: 2rem;
    color: $white;
  }
}

.headline {
  margin-bottom: 2px;
  font-size: $font-size-20;
  color: $white;
  text-align: center;
  font-weight: bolder;
  line-height: normal;
  letter-spacing: 0.0125rem;

  @media (max-width: $viewport--max-width-ms) {
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 1rem;
    letter-spacing: 0.00875rem;
  }
}

.subline1 {
  font-size: $font-size-14;
  font-weight: bold;
  display: inline;
  color: $white;
  text-align: center;
  line-height: 1rem;
  letter-spacing: 0.00875rem;
  margin-bottom: 1rem;
}

.subline2 {
  @extend .subline1;
  font-weight: normal;

  @media (max-width: $viewport--max-width-ms) {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.00875rem;
  }
}

.button {
  background-color: $white;
  color: $brand-orange;
  padding: 0 4rem;
  height: 36px;

  &:hover {
    background-color: $lightgrey;
  }
}
