@import 'src/theme/variables';

.grid_container {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: $viewport--max-width-ms) {
    grid-template-columns: 1fr;
  }

  .introduction_video_container {
    height: 100vh;
    width: 50vw;
    position: fixed;

    @media (max-width: $viewport--max-width-ms) {
      height: auto;
      display: none;
    }

    .introduction_video {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-items: center;
      justify-content: center;
      background-color: #091A33;

      @media (max-width: $viewport--max-width-ms) {
        display: none;
      }

      .video {
        width: 100%;
      }
    }
  }

  .layout {
    width: 50vw;
    margin-left: 50vw;
    display: flex;
    justify-content: center;
    justify-items: center;
    @media (max-width: $viewport--max-width-ms) {
      width: 100vw;
      margin-left: 0;
    }
    .recaptcha {
      display: flex;
      justify-items: center;
      justify-content: center;
      width: 70%;
    }

    .hse_apps, .footer {
      width: 100%;
      margin-top: $space-16;
    }

    .layout_content {
      min-height: calc(100vh - 5.5rem);
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: $space-16;

      .layout_header{
        display: flex;
        justify-content: space-between;
        width: 100%;

        .arrow_back{
          width: 100%;
          display: flex;
          justify-content: flex-start;
        }
      }

      .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: $space-32 $space-32;

        @media (max-width: $viewport--max-width-s) and (max-device-height: 454px) {
          padding: 0 $space-16;
        }
      }
    }
  }
}

