@import 'src/theme/variables';

.content {
  padding: $space-16 $space-16;
  font-size: $font-size-16;
  line-height: 28px;
}

.header {
 padding: $space-16 $space-48 0 $space-16;
}

.dialog_border {
  border-radius: $space-8;
}

.dialog_paper {
  margin: 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top-right-radius: $space-8;
  border-top-left-radius: $space-8;
  padding-bottom: $space-8;
}

.actions {
  padding: 0 $space-16 $space-16;
  display: inline;


}
.button{
  margin: $space-8;
  width: 95%;
}

.close_button {
  position: absolute;
  right: $space-16;
  top: $space-16;
  color: $black;
}

.loading {
  width: 1.5rem;
  height: 1.5rem;
}
