@import '~@hse24/shared-components/styles/colors';

.app_build_info_wrapper {
  margin: 10px 0;
  text-align: center;

  .app_build_info {
    font-size: 12px;
    font-weight: 400;
    color: $color--iron;
  }
}
