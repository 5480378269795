@import 'src/theme/variables';

.container {
  flex: 0 0 50%;
  border-radius: 0.5rem;
  cursor: pointer;
  background: rgba(124, 77, 255, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 1.25rem 1rem;

  @media (max-width: $viewport--max-width-ms) {
    padding: 1.5rem 1rem;
  }

}

.circle {
  width: 3.5rem;
  height: 3.5rem;
  border: 1px solid $white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: $space-16;

  @media (max-width: $viewport--max-width-ms) {
    width: 3.75rem;
    height: 3.75rem;
  }

  svg {
    width: 2rem;
    height: 2rem;
    color: $white;
  }
}

.headline {
  font-size: $font-size-24;
  font-weight: 700;
  color: $white;
  text-align: center;
  margin-bottom: $space-12;

  @media (max-width: $viewport--max-width-ms) {
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.00875rem;
  }
}

.subline {
  margin-bottom: $space-16;
  font-size: $font-size-16;
  color: $white;
  text-align: center;
  font-weight: bold;
  line-height: 1.75rem;

  @media (max-width: $viewport--max-width-ms) {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1rem;
  }
}

.button {
  background-color: $white;
  color: $brand-orange;
  padding: 0 4rem;
  height: 36px;

  &:hover {
    background-color: $lightgrey;
  }
}
