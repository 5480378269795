@import '../../../../theme/variables';

.container {
  padding: $space-24;
  width: 400px;
  height: 252px;
}

.title {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.75rem;
  margin-bottom: 2.5rem;
}

.content {
  font-size: $font-size-16;
  line-height: 1.74rem;
  margin-bottom: $space-16;
}

.close_icon {
  color: black;
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.button {
  width: 100%;
}
