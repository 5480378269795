@import 'src/theme/variables';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $space-16;

  .header {
    font-weight: $font-weight-9;
    font-size: $space-24;
  }

  .buttons_wrapper {
    display: flex;
    gap: $space-8;
  }
}
