@import 'src/theme/variables';

.carouselSlide {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  max-width: 400px;
  min-height: 320px;
  user-select: none;

  @media (max-width: $viewport--max-width-ms) {
    max-width: 35rem;
    max-height: 40rem;
  }

  @media (min-width: #{($viewport--max-width-ms + 1)}) {
    min-width: 400px;
  }

  .content {
    position: relative;
    margin: $space-16;
    z-index: 1;
    max-width: 100%;
    height: 100%;
    max-height: calc(100% - 2 * $space-16);
    overflow: hidden;
  }

  .title {
    font-size: $font-size-24;
    margin-bottom: $space-8;
  }

  .description {
    font-size: $font-size-18;
    margin-top: $space-16;
  }

  .button {
    position: absolute;
    bottom: $space-16;
    right: $space-16;
    font-weight: $font-weight-4;
    z-index: 1;
    display: inline-flex;
    width: auto;
  }

  img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    pointer-events: none;
    object-position: left bottom;
  }
}

.contentSlider {
  width: 100%;
  padding-top: $space-16;
  position: relative;
  max-width: 100%;
  overflow-x: clip;
}

.emblaViewport {
  width: 100%;
}

.slidesWrapper {
  display: flex;
  gap: $space-16;
  will-change: transform;
}

.slide {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  overflow: hidden;
  max-width: 400px;
  min-height: 320px;
  user-select: none;

  @media (max-width: $viewport--max-width-ms) {
    min-width: 280px;
    max-width: 35rem;
    max-height: 40rem;

    &:first-child {
      padding-left: $space-24;
    }

    &:last-child {
      padding-right: $space-24;
    }
  }

  @media (min-width: #{($viewport--max-width-ms + 1)}) {
    min-width: 400px;
  }
}
