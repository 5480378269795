.item {
  color: white;
  background: rgba(8, 31, 62, 0.5);
  border-radius: 0.25rem;
  display: flex;
  width: fit-content;
  margin: 0.125rem 0 0.25rem;
  align-items: center;

  &.follow {
    background: rgba(167, 12, 255, 0.5);
  }

  .icon {
    margin-right: 1rem;
  }

  .username {
    margin-right: 0.5rem;
    opacity: 0.8;
  }

  .content {
    font-weight: 800;
  }
}
