@import 'src/theme/variables';

.table {
  width: 41rem;

  @media (max-width: $viewport--min-width-ms) {
    width: 100%;
  }
}

.table_container {
  min-width: 34rem;
  overflow-x: hidden;

  @media (max-width: $viewport--min-width-ms) {
    overflow-x: scroll;
  }
}
