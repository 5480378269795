@import 'src/theme/variables';

$show_details_right_width: 261px;

.container {
  max-width: 1200px;
  min-width: 716px;
  width: 100%;
  height: 200px;
  background-color: white;
  display: flex;
  border-radius: 8px;
  position: relative;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    animation: fadeIn 0.5s ease-in;
    z-index: 20;
  }

  .images_container {
    min-width: 160px;
    height: 200px;
    position: relative;

    .show_image {
      max-width: 100%;
      max-height: 100%;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      object-fit: cover;
    }

    .show_image_placeholder {
      width: 100%;
      height: 100%;
      background-color: $lightgrey-light;
      position: relative;
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;

      .show_image_placeholder_wrapper {
        position: absolute;
        width: fit-content;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: $space-16;

        .icon {
          align-self: center;
          color: $night-blue-nightblue-3-light;
        }

        .placeholder {
          text-wrap: nowrap;
          font-size: $font-size-14;
          border-bottom: 1px solid black;
          cursor: pointer;
        }
      }
    }

    .streamer_image_overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: linear-gradient(
                      180deg,
                      rgba(0, 0, 0, 0) 61%,
                      rgba(0, 0, 0, 0.33) 80%,
                      rgba(0, 0, 0, 0.5) 100%
      );
      border-bottom-left-radius: 8px;
    }

    .streamer_info {
      position: absolute;
      bottom: $space-8;
      display: flex;
      z-index: 2;
      align-items: center;
      justify-content: center;
      gap: $space-8;
      margin: 0 $space-8;

      .streamer_avatar {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }

      .streamer_name {
        color: white;
        font-weight: 700;
        font-size: $font-size-14;
        line-height: 16px;
        cursor: pointer;

        &.streamer_name_dummy {
          border-bottom: 1px solid;
          line-height: 20px;
        }

        &.streamer_name_no_preview {
          color: black;
          font-weight: normal;
        }

      }

    }
  }

  .show_details {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: hidden;
    justify-content: space-between;
    position: relative;

    .show_details_left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: calc(100% - $show_details_right_width + $space-64);
      padding: 15px $space-24;

      .text {
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .date {
        @extend .text;
        color: $color--iron;
        text-transform: uppercase;
        font-size: $font-size-16;
        font-weight: 700;
        line-height: 26px;
        margin-bottom: $space-12;
      }

      .sales_topic {
        @extend .text;
        color: $color--iron;
        font-size: $font-size-12;
        line-height: 18px;
      }

      .title {
        @extend .text;
        font-size: $font-size-16;
        line-height: 24px;
        color: $color--dark;
        margin-bottom: $space-8;
        width: fit-content;

        &.title_placeholder {
          border-bottom: 1px solid black;
          cursor: pointer;
        }
      }

      .product_slider {
        div {
          div {
            margin-bottom: 0;
            margin-top: 0;
          }
        }
      }

      .products_placeholder {
        width: 275px;
        height: 80px;
        background-color: $lightgrey-lightest;
        border: 2px dashed $lightgrey;
        border-radius: 4px;
        position: relative;
        cursor: pointer;

        .placeholder {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-wrap: nowrap;
          font-size: $font-size-14;
          border-bottom: 1px solid black;
          cursor: pointer;
        }
      }
    }

    .show_details_right {
      position: absolute;
      right: $space-24;
      top: 15px;
      margin-bottom: 15px;
      height: calc(100% - 30px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      min-width: $show_details_right_width;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 20%);
      z-index: 30;

      .show_icon_buttons {
        display: flex;
        gap: $space-8;
        pointer-events: auto;

        svg {
          height: 24px;
          width: 24px;
        }

        .icon_button {
          background-color: $lightgrey-lightest;
          pointer-events: auto;

          div {
            width: unset !important;
            height: unset !important;
          }
        }

        .delete_icon_button {
          @extend .icon_button;

          color: $color--error-red;
        }
      }

      .show_info_button_wrapper {
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
        pointer-events: auto;

        .show_info_button {
          color: $black;
          font-size: $font-size-16;
          font-weight: normal;
          line-height: 24px;
          padding: 0;
          width: fit-content;

          span {
            border-bottom: 1px solid black;
          }
        }
      }
    }
  }
}
