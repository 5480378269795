@import 'src/theme/variables';

.container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding-bottom: env(safe-area-inset-bottom, $space-12);
}

.label {
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
  min-width: 0;
  flex-grow: 1;
  flex-basis: 0;
  padding: 4px 12px;
}
