@import 'src/theme/variables';

.text_field {
  position: relative;

  .input {
    background-color: $white;
    cursor: not-allowed;
  }

  .error_message {
  margin-top: $space-8;
  }
  .char_count {
    flex: 1 0;
    font-size: $font-size-14;
    line-height: 18px;
    margin-top: $space-4;
    text-align: right;
    color: $iron;
  }

  .input_info {
    display: flex;
    justify-content: space-between;
  }
}
.disabled_text_field .input {
  background-color:transparent;
  border-color: $lightgrey;
  cursor: not-allowed ;
}