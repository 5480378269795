@import 'src/theme/variables';

.container {
  min-width: 168px;
  min-height: 298px;
  position: relative;
  border-radius: $scaling-factor;

  &.lg{
    width: 100%;
    aspect-ratio:9/12;
    height: unset;

    @media (max-width: $viewport--max-width-s) {
      width: 100%;
      aspect-ratio:9/12;
      min-height: unset;
      min-width: unset;
    }
  }

  .badge {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 100px;
    height: 18px;
    padding-inline: 8px;
    border-radius: 4px;
    background-color: $black;
    z-index: 1;

    &.text {
      color: $white;
      font-size: 12px;
      font-weight: 700;
    }
  }
}

.poster {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: $button-border-radius;
}

.poster_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: $button-border-radius;
}

.show_details_container {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.10);
}

.show_details {
  overflow: hidden;
  display: flex;
  white-space: normal;
  font-weight: bold;
  flex-direction: row;
}

.show_content {
  @extend .show_details;
  font-size: $font-size-14;
  line-height: $font-size-16;
  color: $color--white;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
  padding: $space-16 $space-8;
  background: rgba(0, 0, 0, 0.10);

  .content_wrapper{
    position: relative;
    z-index: 2;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: linear-gradient(0deg, rgba(8, 31, 62, 0.5) 15.96%, rgba(61, 61, 61, 0) 58.36%);
    z-index: 1;
    border-bottom-left-radius: $button-border-radius;
    border-bottom-right-radius: $button-border-radius;
    backdrop-filter: blur(4px);
  }
}

.show_label_container {
  padding: $space-8;
  top: $space-8;
  position: absolute;
}

.showLabel {
  height: 24px;
  font-size: $font-size-14;
  font-weight: bold;
  line-height: $font-size-16;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $button-border-radius;
  padding: $space-8;
  background: $color--white;
  min-width: 80px;
  color: $primary;
  border: solid 1px $primary;
}
