@import '~@hse24/shared-components/index';
@import '~@hse24/shared-components/styles/breakpoints';
@import 'src/theme/variables';

.categoriesSlider {
  $gutter-size: $grid--offset-s;
  $gutter-size-ms: 26px;

  margin-right: -$gutter-size;
  margin-left: -$gutter-size;
  padding: 0 $gutter-size;

  div {
    &:hover,
    &:focus,
    &:visited,
    &:active {
      outline: none;
    }
  }
}

.category {
  border-radius: $button-border-radius;
  font-size: 14px;
  font-weight: 700;
}

.categorySelected {
  border: 1.5px solid $brand-orange;
  color: $brand-orange;
  background: $white;
  font-weight: 700;
}

.searchText {
  color: $nightblue;
}
