@import 'src/theme/variables';

.grid {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.tab{
  color: $iron;
}

.modal {
  border-radius: 8px;
  min-height: 90vh;
  min-width: fit-content;

  @media (max-width: $viewport--min-width-ms) {
    border-radius: 0;
    min-height: 100vh;
    min-width: 100%;
  }
}

.modal_content {
  @supports (padding-top: env(safe-area-inset-top)) {
    padding-top: env(safe-area-inset-top, $space-18);
  }
}
