@import '../../../../theme/variables';

.alert_wrapper {
  max-width: 100%;
  z-index: 999;
  position: fixed;
  padding: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .alert {
    background-color: #e6f3fa;
  }

  .title {
    color: #013654;
    font-weight: bold;
  }

  .icon {
    color: #0288d1;
  }

  &.extra_margin {
    bottom: 55px;
  }

  .content {
    color: black;
    font-weight: 700;
  }
}
