@import 'src/theme/variables';

.search_item_container {
  width: 140px;
  height: 250px;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px
}

.delete_icon {
  position: absolute;
  top: -10px;
  right: -10px;
  color: $brand-orange;
  cursor: pointer;
}

.search_item_details {
  margin: 6px;
}

.search_item_name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search_item_price {
  overflow: hidden;
  text-overflow: ellipsis;
}
