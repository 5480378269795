@import 'src/theme/variables';

.shows_slider {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  padding-bottom: $space-16;
  column-gap: $space-8;
}

.container {
  margin-bottom: $space-24;
}

.no_shows_card{
  padding: $space-24;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 1rem;
}
