@import 'src/theme/variables';

.wrapper {
  padding: $space-24 0;

  .page_title {
    display: none;
    margin-bottom: $space-48;

    @media (min-width: $viewport--min-width-md) {
      display: block;
    }
  }

  h1 {
    font-weight: 700;
    font-size: $font-size-24;
    margin-bottom: $space-24;
  }

  h2 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  li {
    padding: 0;

    svg {
      color: $black;
    }
  }

  .container {
    .title {
      margin-bottom: $space-12;
      font-weight: $font-weight-7;
    }

    .desktop_actions {
      display: none;
      gap: 21px;

      button {
        flex: 1;
      }
    }

    .inputs {
      height: fit-content;
    }

    @media (min-width: $viewport--min-width-md) {
      flex-direction: row-reverse;

      .desktop_actions {
        display: flex;
      }
    }
  }

  .actions {
    flex-wrap: nowrap;
    margin-top: $space-24;

    button {
      padding: 0 $space-16 !important;
    }

    @media (min-width: $viewport--min-width-md) {
      display: none;
    }
  }

  .checkbox {
    margin-top: $space-16;
  }

  .date_Selector {
    display: none;
    margin-bottom: $space-8;
  }

  .error_message {
    color: $venetian_red;
  }

  .products_error {
    margin-bottom: $space-48;
  }
}

.card {
  border: 1px solid $nightblue-lightest;
  width: 100%;
  background: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: calc($space-24 + $space-8 / 2);
  gap: $space-8;

  .icon {
    color: $black;
  }
}

.card_error {
  border: 1px solid $venetian_red;
  @extend .card;

  .icon {
    color: $venetian_red;
  }
}

.date_button {
  width: fit-content;
  padding-inline: $space-16 !important;

  .aside_icon {
    margin-left: $space-10;
    width: $space-20;
    height: $space-20;
  }
}

.label {
  margin-bottom: $space-12;
  font-weight: $font-weight-7;
}

.goback_header{
  font-size: $font-size-16;
  line-height: $space-20;
  font-weight: $font-weight-4;
  margin-bottom: $space-16;
  cursor: pointer;
}