@import 'src/theme/variables';

.episode {
  display: flex;
  flex-direction: column;
}

.preview_image {
  aspect-ratio: 16/9;
  background: $light-grey;
  border-radius: .25rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.preview_skeleton {
  aspect-ratio: 16/9;
  border-radius: .25rem;
}

.player {
  width: 100%;
  aspect-ratio: 16/9;
}

.header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: .5rem .25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dialog_content {
  background: $whiteLilac;
}

.paper {
  padding: 1rem;
  background: $white;
}

.expend_more {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  gap: .5rem;
  cursor: pointer;
  border-top: 1px solid $lightgrey-mid;
  padding: 1rem;
}