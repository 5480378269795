@import 'src/theme/variables';

.profile_image {
  padding: 0;
}

.menu {
  .menuButton {
    width: 13rem;
    font-size: $font-size-14;
    color: $black;
  }

  .menuIcon {
    margin-right: $space-8;
  }
}
