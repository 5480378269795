@import 'src/theme/variables';

.wrapper {
  background-color: $color--white;
  width: 100%;
  max-width: $viewport--max-width-md;
  margin: auto;
  font-family: Lato, sans-serif;
  font-size: $font-size-16;
  line-height: $font-size-24;

  div {
    margin-bottom: $space-12;

    button {
      color: $brand-orange;
    }
  }

  p {
    margin: $space-16 auto;
  }

  ul {
    margin: $space-16 auto;
    li {
      margin: $space-12 auto;
    }
  }

  h1 {
    font-size: $font-size-28;
    font-weight: 500;
    margin-bottom: $space-18;
  }

  h2 {
    font-size: $font-size-24;
    font-weight: 500;
    margin-bottom: $space-16;
  }

  h3 {
    font-size: $font-size-20;
    font-weight: 500;
    margin-bottom: $space-12;
  }

  h4 {
    font-size: $font-size-16;
    font-weight: 600;
    margin-bottom: $space-8;
  }

  .index {
    margin-bottom: $space-48;
    ul {
      margin: 0;
      li {
        display: list-item;
        margin: 0 $space-12;
        a {
          text-decoration: underline;
        }
      }
    }
  }
}
