@import 'src/theme/variables';

.dropdown {
  position: relative;
  display: inline-block;
  width: max(100%, 270px);
}

.dropdown_header {
  padding: 4px $space-12;
  border: 2px solid $lightgrey;
  cursor: pointer;
  background-color: $white;
  height: 3.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 200px;
}

.dropdown_options, .loading_container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: $white;
  z-index: 1000;
}

.dropdown_options {
  max-height: 200px;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: $iron transparent;
  scrollbar-gutter: unset;
  min-width: 200px;
}

.dropdown_options::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

.dropdown_options::-webkit-scrollbar-track {
  background: $white;
  border-radius: 10px;
}

.dropdown_options::-webkit-scrollbar-thumb {
  background: $iron;
  border-radius: 10px;
  box-shadow: inset 0 0 6px $iron;
}

.dropdown_options::-webkit-scrollbar-thumb:hover {
  background: $iron;
}

.dropdown_option {
  padding: 10px;
  cursor: pointer;
}

.input {
  border : none;
  padding: 0 $space-8;
  height: inherit;
  width: 100%;
}

.loading_container {
  display : flex;
  justify-content: center;
}
