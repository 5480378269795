@import 'src/theme/variables';

.box {
  background: $white;
  padding: 1rem;
}

.header {
  border-bottom: 1px solid $lightgrey-light;
}

.ama_responses {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 35rem;
}

.ama_response {
  border-bottom: 1px solid $lightgrey-light;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}