@import 'src/theme/variables';

.container {
  margin-bottom: $space-8;
  max-width: 75%;
  margin-left: 25%;
  margin-top: $space-24;

  @media (max-width: $viewport--min-width-md) {
    max-width: 100%;
    margin-left: 0;
  }
}

.table {
  margin-top: $space-24;
}