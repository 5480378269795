@import 'src/theme/variables';

.overlay {
  z-index: 1000;
}

.dialog_paper {
  height: calc(100% - 132px);
}

.dialog {
  display: flex;
  flex-direction: column;
  height: 100%;

  .title_container {
    position: relative;
    display: flex;
    align-items: center;
    padding: $space-32 $space-48;
    flex-direction: row-reverse;

    .cancel {
      position: absolute;
      top: $space-12;
      right: $space-12;
      cursor: pointer;
    }

    .title {
      width: 100%;
      font-size: $font-size-20;
      line-height: 20px;
      letter-spacing: 0.14px;
      text-align: start;
    }
  }

  .content {
    flex-grow: 1;
    width: 100%;
    position: relative;

    .crop {
      position: absolute;
      height: 100%;
      left: 50%;
      right: 50%;
      transform: translate(-50%);

      .crop_playground {
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        position: relative;
        width: 100%;
        height: 100%;

        .cropper_container {
          margin: auto;
          background: $white;
        }

        .crop_aria {
          border: 2px solid $white;

          .overlay::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 11%;
            background: rgba(0, 0, 0, 0.4);
          }

          .overlay::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 27%;
            background: rgba(0, 0, 0, 0.4);
          }
        }
      }
    }
  }

  .actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: $space-24 $space-48 $space-32 $space-48;

    .hint {
      font-family: Lato;
      font-size: $font-size-14;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      padding: 0 $space-16;
    }

    .crop_zoom_slider {
      width: 9.3rem;
      margin: auto;
    }

    .buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      gap: $space-16;

      > * {
        flex: 1 0 0;
      }
    }
  }
}

@media (max-width: $viewport--min-width-md) {
  .dialog {
    background: $black;

    .title_container {
      flex-direction: row;
      padding: $space-12 $space-16;
      color: $white;

      .cancel {
        color: $white;
        opacity: 1;
        margin-right: 0;
      }

      .title {
        text-align: center;
        margin-left: 0;
        font-size: $font-size-16;
        font-weight: bold;
      }
    }

    .content {
      .crop {
        width: 100%;

        .crop_playground {
          .cropper_container {
            width: 100%;
            background: $black;
          }
        }
      }
    }

    .actions {
      padding: $space-8 0 0 0;

      .hint {
        color: white;
      }

      .crop_zoom_slider span {
        color: $white;
      }
    }
  }
}
