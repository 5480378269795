@import 'src/theme/variables';

.title_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $space-24;
  height: $space-64;
  grid-template-columns: 1fr auto 1fr ;



  .title {
    width: 100%;
    font-size: $font-size-20;
    line-height: 28px;
    padding-left: 1rem;
  }

}

@media (max-width: $viewport--min-width-md) {
    .title_container {
      padding: $space-12 $space-16;

      .cancel {
        opacity: 1;
        margin-right: 0;
      }


      .close_btn{
        width: $space-36;
        height: $space-32;
     }
    }
}
.close_btn{
  width: 2.125rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  justify-content: flex-start;
  padding: 0 0 !important;
  margin-right: 1rem;

}
.close_icon{
  width: 0.725rem;
  height: 0.725rem;

}
