@import 'src/theme/variables';

.product_container {
  width: 100%;
  padding: $space-12;
  position: relative;
  cursor: pointer;

  &.not_selectable{
    cursor: not-allowed;
  }
  .highlighted {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 15px;
    color: $brand-orange;
  }

  .product_image {
    height: 100px;
    border-radius: $space-8;
  }

  .product_details {
    padding: $space-8;

    .product_brand {
      font-weight: bold;
      font-size: $font-size-11;
      color: $grayDark;
    }

    .product_name {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .product_price {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
