@import 'src/theme/variables';

.container {
  width: 100%;
  margin: auto;
  background-color: $white-lilac;
}

.table_filter {
  margin-top: $space-32;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  gap: 1rem;

  @media (max-width: $viewport--max-width-md) {
    margin-top: $space-20;
  }
}
