@import 'src/theme/variables';

.header{
  margin-left: $space-36;

}
.title {
  margin-top: $space-16;
  margin-left: $space-36;
}
.goback_header{
  margin-top: $space-42;
  margin-left: $space-32;
  cursor: pointer;
}
.container {
  margin: $space-8;

  @media (min-width: $viewport--min-width-ms) {
    margin-inline: $space-48;
  }

  @media (min-width: $viewport--min-width-md) {
    margin-inline: calc($space-48 + 112px);
  }

  @media (min-width: $viewport--min-width-xl) {
    margin-inline: calc($space-48 + 222px);
  }

}