@import '~@hse24/shared-components/styles/colors';
@import 'src/theme/variables';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $space-16;
}

.hits {
  height: 100%;
  color: $color--iron;
  font-size: $font-size-16;
}

.sort_and_search_wrapper {
  display: flex;
  gap: $space-16;

  .sort {
    width: 230px;
    @media (max-width: $viewport--min-width-ms) {
      width: 52px;
    }
  }

  .search_wrapper {
    display: flex;
    align-items: center;
    background-color: white;
    border: 2px solid $lightgrey;

    .search {
      text-overflow: ellipsis;
      overflow: hidden;
      text-wrap: nowrap;
      max-width: 192px;
      height: $space-36;
      border: none;
    }

    .divider {
      margin: auto 0;
      width: 2px;
      height: $space-24;
      background-color: $lightgrey;
      padding: 1px;
    }

    .search_icon {
      margin: $space-6 $space-14;
    }
  }

  .sort_option {
    width: 305px;
    transform: translate(calc(230px - 305px), 0);
    @media (max-width: $viewport--min-width-ms) {
      transform: translate(calc(52px - 305px), 0);
    }
  }
}

.shows_wrapper {
  display: flex;
  flex-direction: column;
  gap: $space-24;
  margin-bottom: $space-24;
  overflow-x: auto;
}

.pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  span:first-of-type {
    margin-right: $space-8;
    font-size: $font-size-12;
    line-height: 18px;
  }

  button {
    height: 32px !important;
  }

  .pagination_select {
    margin-right: $space-32;

    span {
      font-size: $font-size-16;
    }
  }

  .pagination_info {
    margin: 0 $space-24;
  }

  .pagination_button {
    span:first-of-type {
      margin-right: 0;
    }
  }
}
