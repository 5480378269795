@import 'src/theme/variables';

.mobileFilterBarHeader {
  background-color: $white;
  position: relative;
  padding: 1rem;
  box-shadow: 0 0 20px $color--box-shadow;

  .label {
    display: block;
    position: relative;
    font-weight: 700;
  }

  .subLabel {
    color: $iron;
  }

  .close {
    margin-left: auto;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0.5rem;
    color: $brand-orange;
  }
}
