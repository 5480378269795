@import "src/theme/variables";

.time_option {
  text-align: center;
  cursor: pointer;
  padding: $space-8 $space-12;
  border-radius: $space-8;
  border: 1px solid rgba(0, 0, 0, 0.23);
  font-weight: $font-weight-4;
}

.selected {
  background-color: $primary;
  color: $white;
  font-weight: $font-weight-7;
}
