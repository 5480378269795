@import 'src/theme/variables';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: $space-4;

  .price {
    font-weight: 700;
    font-size: $space-16;
    margin-right: 0.5rem;
    line-height: 24px;
  }

  .reference_price {
    font-size: 0.875rem;
    font-weight: 400;
    text-decoration: line-through;
    line-height: 24px;
  }

  .saving_percent {
    color: white;
    background-color: $sale-red;
    font-size: 0.875rem;
    font-weight: 700;
    display: flex;
    justify-content: flex-start;
    height: 1.5rem;
    line-height: 24px;
    padding-left: $space-8;
    padding-right: $space-8;
    margin-right: $space-8;
    width: auto;
  }

  .disabled {
    color: $iron;
  }
}
