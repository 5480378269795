@import '../../../../theme/variables';

.alert_wrapper {
  max-width: 100%;
  z-index: 999;
  position: fixed;
  padding: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white !important;
  border-top: #e5e5e5 1px solid;
  border-bottom: #e5e5e5 1px solid;

  .alert {
    background-color: white !important;
  }

  .title {
    color: rgba(1, 54, 84, 1);
    font-size: 1rem;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.00938rem;
  }

  &.extra_margin {
    bottom: 55px;
  }

  &.desktop {
    padding: 0 5rem;
    border-bottom: none;
  }

  .content {
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.875rem;
    line-height: 142%;
  }
}
