@import 'src/theme/variables';

.text_area {
  position: relative;
  width: 100%;
}
.char_count {
  flex: 1 0;
  font-size: $font-size-14;
  line-height: 18px;
  margin-top: $space-4;
  text-align: right;
  color: $iron;
}
  .input {
    width: 100%;
    padding: $space-24 $space-16 $space-8;
    background-color: $white;
    border: 2px solid $lightgrey ;
    border-radius: 0;
    outline: none;
    resize: none;
    font-size: $space-16;
  }
.error {
  border: 2px solid $red;
  border-radius: 0;
}

.input::placeholder {
  color: transparent;
}

.text_area::before {
  content: attr(data-placeholder);
  position: absolute;
  top: $space-8;
  left:$space-16;
  color: $iron;
  font-size: $space-14;
  pointer-events: none;
  transition: all 0.2s ease;
}

.text_area textarea:focus + .text_area::before,
.text_area textarea:not(:placeholder-shown) + .text_area::before {
  top: $space-4;
  transform: none;
  font-size: $space-14;
  color: $iron;
}
  .error_message {
    margin-top: $space-8;
  }

  .input_info {
    display: flex;
    justify-content: space-between;
  }

.disabled_text_field .input {
  background-color:transparent;
  border-color: $lightgrey;
  cursor: not-allowed ;
}