@import 'src/theme/variables';

.main {
  width: 100%;
  height: 100%;
  background-color: $white-lilac;

  .container {
    width: auto;
    height: 100%;
    margin: auto;

    .box{
      margin-top: $space-32;
      
      @media (max-width: $viewport--max-width-md) {
        margin-top: $space-20;
      }
    }
  }
button{
  width: auto;
}
}
