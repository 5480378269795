@import 'src/theme/variables';

.skeleton_body {
  position: relative;
  background-color: rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  min-width: 220px;
  min-height: 330px;

  &.lg{
    width: 100%;
    aspect-ratio:9/12;
    height: unset;
  }

  .skeleton_content {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: $space-16 $space-8;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
