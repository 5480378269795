@import 'src/theme/variables';

.bar {
  position: relative;
  width: 300px;
  height: 4px;
  border-radius: 30px;
  background-color: $night-blue-nightblue-4-lightest;

  .loaded {
    position: absolute;
    height: 100%;
    background-color: $brand-orange;
  }
}
