@import 'src/theme/variables';

.wrapper {
  padding: $space-24 0;

  .page_title {
    display: none;
    margin-bottom: $space-48;

    @media (min-width: $viewport--min-width-md) {
      display: block;
    }
  }

  h1 {
    font-weight: 700;
    font-size: $font-size-24;
    margin-bottom: $space-24;
  }

  h2 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  li {
    padding: 0;

    svg {
      color: $black;
    }
  }

  .container {
    .title {
      margin-bottom: $space-12;
      font-weight: $font-weight-7;
    }

    .desktop_actions {
      display: none;
      gap: 21px;

      button {
        flex: 1;
      }
    }

    @media (min-width: $viewport--min-width-md) {
      flex-direction: row-reverse;

      .desktop_actions {
        display: flex;
      }
    }
  }

  .actions {
    flex-wrap: nowrap;
    margin-top: $space-24;

    button {
      padding: 0 $space-16 !important;
    }

    @media (min-width: $viewport--min-width-md) {
      display: none;
    }
  }

  .checkbox {
    margin-top: $space-16;
  }

  .date_Selector {
    display: none;
    margin-bottom: $space-8;
  }

  .sales_topic_hint {
    font-size: $font-size-12;
    line-height: $font--line-height-body-micro;
    color: $night-blue-nightblue-2-mid;
  }

  .radio_group {
    display: flex;
    gap: $space-16;
    margin-top: $space-12;

    .radio_buttons {
      display: flex;
      column-gap: $space-24;
      align-items: center;
    }
  }

  .error_message {
    margin-top: $space-8;
  }
}

