@import 'src/theme/variables';

.input_loading{
  width: 1.5rem;
  height: 1.5rem;
}

.auth_form {
  .auth_form_header {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  
  .auth_form_fields {
    width: $form--width;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: $space-72;

    @media (max-width: $viewport--max-width-s) {
      margin-top: $space-60;

      @media (max-device-height: 814px) {
        margin-top: $space-42;
      }

      @media (max-device-height: 550px) {
        margin-top: $space-16;
      }
    }

    .fields {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: $space-24;
    }

    .field {
      margin-top: $space-12;
    }
  }

  .auth_form_actions {
    width: $form--width;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: $space-42;

    @media (max-width: $viewport--max-width-s) {
      margin-top: $space-60;

      @media (max-device-height: 814px) {
        margin-top: $space-42;
      }

      @media (max-device-height: 550px) {
        margin-top: $space-16;
      }
    }

    .logged_in {
      font-size: $font-size-12;
    }
  }
}
