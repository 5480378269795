@import 'src/theme/variables';

.alert {
  position: absolute;
  left: $space-16;
  margin-bottom: $space-16;;
  width: calc(100% - $space-32);
  z-index: 999;

  .alert_title {
    margin-block: 0 calc($space-8 / 2);
  }
}
