@import 'src/theme/variables';

.container {
  display: flex;
  gap: 19px;

  .show_card {
    width: calc(100% - $space-24);
  }

}

.buttons {
  display: flex;
  align-items: center;
  gap: $space-8;

  .btn {
    font-size: $font-size-16;
    font-weight: $font-weight-7;
    line-height: $space-24;
    flex-grow: 4;

    @media (max-width: $viewport--max-width-s) {
      padding: $space-8 10px !important;
    }
  }

  .card_menu {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: $white;
  }

  @media (min-width: $viewport--min-width-ms) {
    max-width: 290px;

    .card_menu {
      background-color: $lightgrey-lightest;
    }
  }
}

.three_dots_icon {
  color: $black;
}

.multiLineEllipsis {
  font-size: $font-size-18;
  font-weight: $font-weight-7;
  line-height: 22px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-block: $space-12;
}

.card {
  margin-bottom: $space-48;
  width: 208px;

  &.large {
    width: min(348px, 100%);

    .image_container::before {
      @media (min-width: $viewport--min-width-ms) {
        width: 265px;
      }
    }

    .date_badge {
      display: block;

      @media (min-width: $viewport--min-width-ms) {
        display: none;
      }
    }
  }

  @media (min-width: $viewport--min-width-ms) {
    display: flex;
    flex-direction: row;
    width: 100%;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 366px;
      background: $white;
      padding: $space-24 0 $space-24 $space-24;
    }

    &.large {
      width: 100%;

      .content {
        width: 411px;
      }
    }
  }

  .image_container {
    position: relative;

    .poster {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .image_container::before {
    content: '';
    display: block;
    padding-top: 125%;

    @media (min-width: $viewport--min-width-ms) {
      width: 228px;
    }
  }

  .image_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.dark_layer {
      background-color: rgba(0, 0, 0, 0.5019607843);
    }

    @media (max-width: $viewport--max-width-s) {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 11.98%, rgba(0, 0, 0, 0.33) 74.48%, rgba(0, 0, 0, 0.5) 100%);
    }
  }

  .product_slider {
    position: absolute;
    bottom: 0;
    width: min(348px, 100%);

    @media (min-width: $viewport--min-width-ms) {
      display: none;
    }
  }

  .product_slider_desktop {
    margin-top: $space-8;

    @media (max-width: $viewport--max-width-s) {
      display: none;
    }
  }

  .stream_state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 40%;
    width: 100%;
    color: $white;
  }
}

.date_container {
  margin-bottom: $space-24;
}

.badge {
  font-weight: $font-weight-7;
  line-height: $space-18;
  text-transform: uppercase;
  width: fit-content;
  padding: 3px 8px;
  background-color: $night-blue-nightblue-2-mid;
  color: $white;
}

.date_badge {
  @extend .badge;
  position: absolute;
  bottom: 96px;
  margin-left: $space-8;
  display: none;

  @media (min-width: $viewport--min-width-ms) {
    display: none;
  }
}

.date_badge_desktop {
  @extend .badge;

  @media (max-width: $viewport--max-width-s) {
    display: none;
  }
}

.timeline_container {
  position: relative;
  display: flex;

  .circle_icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: $white;
    border: 4px solid $night-blue-nightblue-4-lightest;
    margin-top: 4px;
  }

  .vertical_line {
    position: absolute;
    top: $space-24;
    bottom: 0;
    left: 42%;
    width: 0;
    height: calc(100% - 16px);
    border-left: 3px dashed $night-blue-nightblue-4-lightest;
  }
}

.container:last-child .timeline_container .vertical_line {
  -webkit-mask-image: linear-gradient(180deg, #C1C7CF 0%, rgba(220, 230, 235, 0) 72%);
  mask-image: linear-gradient(180deg, #C1C7CF 0%, rgba(220, 230, 235, 0) 72%);
}
