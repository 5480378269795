@import "src/theme/variables";

.actions {
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: $white-lilac;
  padding: $space-16 0;
  gap: $space-16;
  border-radius: 0px 0px 8px 8px;

  @media (min-width: $viewport--min-width-md) {
    padding: $space-16;
    margin-inline: $space-8;
  }

  @media (max-width: $viewport--max-width-ms) {
    width: 100%;
  }

  .selected_date p {
    padding: 0;
    text-align: center;
    font-weight: $font-weight-4;
  }

  button {
    font-weight: $font-weight-7;
  }
}
