@import 'src/theme/variables';

.label {
  flex: 1;
  color: $grayDark;
  font-size: $font-size-13;
  margin-right: $space-16;
}

.row {
  align-items: flex-end;
  margin: 0;
  padding: $space-12 0;
}

.rowSeparator {
  border-bottom: 2px solid $grayLight;
}

.icon {
  cursor: pointer;
  color: $night-blue-nightblue-4-lightest;
}

.eye_icon {
  cursor: pointer;
}

.resendWelcomeMailBtn {
  margin-top: $space-18;
}

.withLineBreaks {
  line-break: anywhere;
}

.loader {
  width: 1.2rem;
  height: 1.2rem;
}
