@import 'src/theme/variables';

.color_picker{
  margin-top: $space-4;
  margin-left: $space-8;
}

.container, .circle {
  border-radius: 50%;
}

.container {
  width: $space-32;
  height: $space-32;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: $space-8;
}

.circle {
  width: $space-24;
  height: $space-24;
  display: inline-block;
}

.selected {
  border: 1px solid $black;
}