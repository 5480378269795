.text {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
}

.text_row {
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  .text_content {
    align-self: flex-end;
    max-width: 350px;
  }
}
