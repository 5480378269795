@import "src/theme/variables";

.time_options_container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: $space-12;
}

.spinner {
  margin: auto;
}

@media (max-width: $viewport--min-width-ms) {
  .time_options_container {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (max-width: 564px) {
  .time_options_container {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 464px) {
  .time_options_container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 374px) {
  .time_options_container {
    grid-template-columns: repeat(3, 1fr);
  }
}
