@import 'src/theme/variables';

.stat_card {
  border-radius: 8px;
  background: white;
  padding: 1.3rem;

  .stat_icon{
    background-color: rgba(0, 0, 0, 0.06);
    svg{
      color: $black;
    }
  }
}
