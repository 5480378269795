@import '~@hse24/shared-components/styles/colors';
@import 'src/theme/variables';

.checkbox {
  display: flex;
  align-items: flex-start;
  position: relative;
  transition: opacity 0.1s;
  cursor: pointer;
  line-height: 1.63;

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 1.5rem; // same width as <i>
    height: 1.5rem; // same height as <i>
    margin: 0;
  }

  i {
    display: inline-block;
    flex: 0 0 auto;
    margin-right: 0.5rem;
    margin-top: .3rem;
    height: 20px;
    width: 20px;
    position: relative;
    background-color: $color--white;
    border: solid 1px $night-blue-nightblue-4-lightest;
    border-radius: $button-border-radius;
  }
}

.inline {
  display: inline-flex;
}

.checked {
  i {
    border-color: $brand-orange;
  }
}

.checkboxChecked {
  &::after {
    content: '';
    display: inline-block;
    width: 0.6rem;
    height: 0.3rem;
    border: solid 2px $brand-orange;
    border-top-width: 0;
    border-right-width: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.disabled {
  opacity: 0.6;
}

.standAlone {
  // in case we don't want to provide a label to the checkbox, the margin is removed to have a clean component
  i {
    margin-right: 0;
  }
}
