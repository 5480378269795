@import 'src/theme/variables';

.hint {
  width: fit-content;
  position: absolute;
  z-index: 100;
  background-color: $color--curry-lightest;
  border-radius: 5px;
  margin-top: $space-16;
  padding: $space-8 0 $space-8 $space-16;
  hyphens: auto;
  text-align: justify;

  @media (max-width: $viewport--min-width-ms) {
    // Spacing between text and icons not needed on mobile devices, because the hint renders in one column.
    padding: $space-8 $space-16;
  }

  .icon {
    margin-right: $space-16;
    @media (max-width: $viewport--min-width-ms) {
      // Spacing between text and icons not needed on mobile devices, because the hint renders in one column.
      margin-right: 0;
    }
  }

  .button {
    color: $color--dark;
  }
}
