@import 'src/theme/variables';

footer {
  background-color: $white;
  align-items: center;
  justify-content: center;
  padding: $space-16;
  width: 100%;

  .shop_apps_hint {
    width: 100%;
    text-align: center;
    margin-bottom: $space-16;
  }

  .apps {
    display: flex;
    justify-content: center;
    justify-items: center;
    gap: $space-16;
  }
}
