@import '../../theme/variables';

.exit {
  display: flex;
  align-items: center;
  gap: $space-6;
  cursor: pointer;

 .icon {
   color: $iron;
 }
.text {
    font-size: $font-size-14;
    font-weight: $font-weight-4;
    line-height: $space-24;
    letter-spacing: 0.14px;
    color: $iron;
  }
}