@import 'src/theme/variables';

.text_field {
  .input {
    background-color: $white;
  }

  .error_message {
    margin-top: $space-8;
  }
  .char_count {
    display: flex;
    justify-content: flex-end;
    color: #595964;
    text-align: right;
    font-size: $font-size-13;
    line-height: 20px;
    letter-spacing: 0.13px;
    margin-top: $space-8;
  }
}
.disabled_text_field .input {
  background-color:transparent;
  border-color: $lightgrey;
}