@import '~@hse24/shared-components/styles/colors';

.input {
  background-color: white;
  border: 2px solid $color--lightgrey;
  border-radius: 0;

  fieldset {
    border-width: 0;
  }
}
