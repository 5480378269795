@import 'src/theme/variables';

.headline {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 8px;
}

.search_icon {
  color: $primary;
  cursor: pointer;
}

.badge_container {
  margin-left: 6px;
  margin-top: 2px;
}

.badge_header_container {
  display: flex;
}

.products {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .card {
    border: 1px solid $nightblue-lightest;
    width: 100%;
    height: 4.75rem;
    background: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .horizontal_list {
    padding: 0 0 1rem 0;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    display: flex;
    gap: 1rem;
  }
}
