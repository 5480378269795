@import '~@hse24/shared-components/styles/_colors.scss';
@import '~@hse24/shared-components/styles/_spacings.scss';
@import '~@hse24/shared-components/styles/breakpoints';

.wrapper {
  width: 100%;
  max-width: 81rem;

  .charts {
    width: 100%;
    min-height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1.8rem;

    > div {
      flex: 3;
      height: auto;
      margin-right: 0;
      padding: $space-24 $space-24 2.6rem $space-24;
      border: 0.03rem solid $color--lightgrey;
      border-radius: 0.625rem;
    }

    > div:not(:last-child) {
      margin-bottom: 1.25rem;
    }

    @media (min-width: $viewport--min-width-xl) {
      flex-direction: row;

      > div:not(:last-child) {
        margin-right: 0.625rem;
        margin-bottom: 0;
      }
    }
  }

  .expanded_charts {
    display: flex;
    flex-direction: column;

    > div:not(:last-child) {
      margin-bottom: 1.25rem;
    }
  }
}
