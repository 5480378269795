@import 'src/theme/variables';

.mobileFilterBarListItem {
  background-color: $white;
  padding: 1rem;
  margin: 1rem;
  border-radius: $button-border-radius;
}

.mobileFilterBarHeadline {
  display: inline-block;
  margin-bottom: 1.5rem;
  font-weight: 700;
}
