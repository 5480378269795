@import '~@hse24/shared-components/styles/viewport-sizes';
@import '~@hse24/shared-components/styles/colors';
@import '~@hse24/shared-components/styles/spacings';
@import '~@hse24/shared-components/styles/fonts';

.wrapper {
  border-radius: 4px !important;

  .truck_icon {
    margin-bottom: 10px;
  }

  .title {
    font-weight: bold;
    margin-bottom: 20px;
  }

  .question {
    margin-bottom: 20px;
  }

  .detail {
    font-weight: bold;
  }

  .reject_link {
    font-family: Lato, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #595964;
    cursor: pointer;
  }

  .yes_button {
    height: 30px;
    width: 80px;
  }

  .action_buttons {
    margin-top: 40px;
  }
}
