@import 'src/theme/variables';

.dialog_paper {
  height: calc(100% - 64px);
  border-radius: 1rem;

  @supports (padding-top: env(safe-area-inset-top)) {
    padding-top: env(safe-area-inset-top, $space-18);
  }
}

.container {
  padding: 30px;


  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: $space-24 0;
    padding: 0;

    .title {
      margin-bottom: $space-16;
    }

    .show_info {
      margin-bottom: $space-32;
    }

    .countdown_note {
      position: absolute;
      bottom: 0;
    }
  }

  .close_button {
    position: absolute;
    right: 15px;
    top: 15px;
    color: $black;
  }

  .actions {
    display: flex;
    align-items: flex-start;
    gap: 15px;

    .delete {
      width: 150px;
      background-color: $error;
    }

    .edit {
      width: 150px;
      border: 1px solid $secondary;
      color: $secondary;
    }
  }
}
