@import 'src/theme/variables';

.creator_info_header {
  display: flex;
  align-items: center;
  column-gap: $space-8;
  margin-bottom: $space-12;
}

.creator_name {
  font-weight: $font-weight-7;
  line-height: 21px;
  color: $primary;
}
