@import "src/theme/variables";

.custom_selector {
  display: flex;
  gap: $space-8;
}

.label {
  padding-top: $space-8;
  font-size: $font-size-14 !important;
  text-align: center;
  font-weight: $font-weight-5;
}

.input {
  background-color: $light-grey-2;
  color: $black;
  font-size: $font-size-32;
  font-weight: $font-weight-7;
  text-align: center;
  height: 75px;
  width: 90px;
  border-radius: $space-8;
}

.input:focus{
  outline: 1px solid $primary ;
}

.error {
  color: $red;
  text-align: center;
  font-size: $font-size-12;
  font-weight: $font-weight-4;
  line-height: $font-size-20;
  letter-spacing: .4px;
}
