@import 'src/theme/variables';

.header {
  font-weight: 700;
  font-size: $font-size-24;
}

.audience_filter_header {
  font-weight: 800;
  font-size: 18px;
}

.container {
  width: 100%;
}

.table_filter {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  margin-top: ($space-32);
  gap: 1rem;

  @media (max-width: $viewport--max-width-md) {
    margin-top: $space-20;
  }
}
