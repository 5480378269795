
.searchResultsList {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.searchResultsItem {
  padding-bottom: 1.5rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
