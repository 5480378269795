@import 'src/theme/variables';

.buttonMoreCol {
  display: flex;
  justify-content: center;
  position: relative;
  bottom: $bottom-navigation-height;
  padding-bottom: 32px;
  padding-top: $bottom-navigation-height + 10px;
}
