@import 'src/theme/variables';

.header {
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
}

.shows {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: $space-8;

  @media (max-width: $viewport--max-width-ms) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.alert {
  margin-bottom: $space-24;

  .alert_title {
    margin-block: 0 calc($space-8 / 2);
  }
}
