@import 'src/theme/variables';

.no_results {
  padding: 18px;
  display: flex;
  column-gap: 16px;
  align-items: self-start;
}

.info_icon {
  color: $brand-orange;
}

