@import 'src/theme/variables';

.appbar {
  box-shadow: none;
  border-bottom: solid 1px $grayLight;
  height: $navbar--height;
}

.toolbar {
  min-height: $navbar--height;
}

.link {
  margin: 0.5rem 0.75rem;
  font-size: $font-size-16;
  color: $iron;

  &:hover {
    &:not(button) {
      color: $primary;
    }
  }
}

.button_link{
  height: 2.5rem;
  border: 1px dashed #555;
  margin: .75rem 0.75rem 0 0.75rem;
}
