@import 'src/theme/variables';

.search_input{
  display: flex;
  justify-content: flex-start;
  height: 3.5rem;
}
.input{
  width: 100%;
  background-color: $lightgrey-light;
  border: none;
  height: $space-40;
  padding-left: $space-16;
  &:focus {
    outline: none;
  }
  font-size: $font-size-16 ;
  border-radius: 0;
}

.button{
  width: $space-48;
  display: flex;
  justify-content: center;
}
.action_icons{
  width: $space-80;
  height: $space-40;
  background-color: $lightgrey-light;
  padding-left: $space-16;
  padding-top: $space-8;
  display: flex;
  justify-content: center;
}

.clear_icon {
  width: $space-14;
  height: $space-14;
  margin-bottom: $space-6;
  margin-right: $space-10;
}
.clear_icon_btn{
  &:hover {
    background-color: inherit;
    cursor: default;
  }
}
.search_icon {
  margin-right: $space-8;
  margin-bottom: $space-4;
}
.divider {
  margin-right: $space-12;
  margin-bottom: $space-8;
  width: 2px;
  height: $space-24;
  background-color: $lightgrey;
  padding: 1px;
}
.label{
  cursor: grab;
}