@import 'src/theme/variables';

.creator_content_container {
  position: relative;

  @media (min-width: $viewport--min-width-ms) {
    margin-inline: $space-48;
  }

  @media (min-width: $viewport--min-width-md) {
    margin-inline: calc($space-48 + 112px);
  }

  @media (min-width: $viewport--min-width-xl) {
    margin-inline: calc($space-48 + 222px);
  }
}

.creatorHeader {
  padding: $space-16 $space-16 0 0 !important;

  @media (max-width: $viewport--max-width-md) {
    padding: $space-16 $space-24 0 $space-16 !important;
  }

  @media (max-width: $viewport--max-width-ms) {
    padding: $space-16 $space-24 0 $space-24 !important;
  }

  @media (min-width: calc(#{$viewport--max-width-md} + 0.02px)) {
    padding: $space-16 $space-16 0 0 !important;
  }
}

.voucher_container {
  position: relative;
  padding-top: $space-40 !important;

  @media (min-width: $viewport--min-width-ms) {
    margin-inline: $space-48;
  }

  @media (min-width: $viewport--min-width-md) {
    margin-inline: calc($space-48 + 112px);
  }

  @media (min-width: $viewport--min-width-xl) {
    margin-inline: calc($space-48 + 222px);
  }

  .voucher_text {
    font-weight: $font-weight-4;
    row-gap: $space-24;

    @media (max-width: $viewport--max-width-ms) {
      margin-left: $space-24;
      margin-right: $space-24;
    }
  }

  .voucher_no_results {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: $space-24;

    .voucher_no_results_text {
      display: block;
      text-align: center;
      font-size: $space-14;
      font-weight: $font-weight-4;
      line-height: $space-20;
      color: $night-blue-nightblue-2-mid;
    }
  }
}
