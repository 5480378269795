@import 'src/theme/variables';

.streamed_by_container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-block: $space-24;

  .form {
    display: flex;
    gap: $space-16;

    .radio_buttons {
      display: flex;
      column-gap: $space-24;
      align-items: center;
    }

    .submit_btn {
      padding-inline: $space-24;
    }
  }

  .hint {
    color: $night-blue-nightblue-2-mid;
  }
}
