@import '../../theme/variables';

.header {
  padding-block: $space-4 $space-8;

  .text {
    font-weight: $font-weight-7;
    font-size: $font-size-36;
    line-height: 140%;
  }
}

.exit {
  display: flex;
  align-items: center;
  gap: $space-4;
  cursor: pointer;
  padding: $space-12 $space-16 $space-12 $space-8;
  color: $night-blue-nightblue-2-mid;
  margin-inline: -$space-24;
  width: fit-content;

  .short_text {
    font-size: $font-size-16;
    line-height: $space-20;
  }

  .text {
    @extend .short_text;
    display: none;
  }

  @media (min-width: breakpoint-min(md)) {
    margin-inline: 0;

    .short_text {
      display: none;
    }

    .text {
      display: block;
    }
  }
}
