@import 'src/theme/variables';

.container {
  width: 100%;
  height: 200px;
  background-color: white;
  display: flex;
  border-radius: 8px;

  .images_container {
    position: relative;

    .streamer_info {
      position: absolute;
      bottom: $space-8;
      display: flex;
      z-index: 2;
      align-items: center;
      justify-content: center;
      gap: $space-8;
      margin: 0 $space-8;
    }
  }

  .show_details {
    margin: 15px $space-24;
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .date_and_action_buttons {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .date {
        margin-bottom: $space-12;
      }

      .show_icon_buttons {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        gap: $space-8;
      }
    }

    .title {
      margin-bottom: $space-8;
    }

    .products_wrapper {
      flex-grow: 1;
      display: flex;
      gap: $space-8;
      position: absolute;
      bottom: 0;
      width: 100%;
      justify-content: flex-start;

      .show_info_button {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
}
