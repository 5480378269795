@import 'src/theme/variables';

.modal_content {
  color: $iron;
  font-size: $font-size-16;
  display: flex;
  row-gap: $space-8;
  flex-direction: column;
}

.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
