@import 'src/theme/variables';

.instruction {
  background-color: $lightgrey-light;
  padding: $space-16;
  .icon_text {
    font-weight: $font-weight-4;
    line-height: $space-24;
    font-size: $font-size-18;
    margin-left: $space-8;
  }

  .instruction_text {
    padding-left: $space-32;
    white-space: pre-line;
    color: $iron;
    width: 100%;
    font-size: $font-size-14;
  }

  &:last-child {
    grid-column: span 2;
    width: 48%;
    justify-self: center;
    @media (max-width: 564px) {
      width: 100%;
    }
  }

  @media (max-width: 564px) {
    width: 100%;
  }

}