@import 'src/theme/variables';

.voucher_info_header {
  display: flex;
  align-items: center;
  column-gap: $space-8;
}
.voucher_form {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: 16px;
}
.voucher_text_field {
  width: 28rem;
}
.divider {
  width: 28rem;
}
.voucher_header_info {
  width: 28rem;
  color: #595964;
}
.voucher_value_info {
  width: 28rem;
  margin: 50px 0 16px 0;
}
.close_icon_button {
  border-radius: 100px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  color: $error;
}
.check_icon_button {
  border-radius: 100px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  color: $success;
}
.check_icon_button_input {
  border-radius: 100px;
  border: 2px solid #2e7d32;
  color: #2e7d32;
  width: 20px;
  height: 20px;
}
.close_icon_button_input {
  border-radius: 100px;
  border: 2px solid #595964;
  color: #595964;
  width: 20px;
  height: 20px;
}
