@import 'src/theme/variables';

.suggestions {
  background: $color--white;
  list-style: none;
  max-height: 50vh;
  overflow-y: auto;
  width: 100%;
  border-radius: 5px;
  box-shadow: 4px 14px 26px 3px #dce6eb;
  border: 1px solid #e4e4e4;
}
