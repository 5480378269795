@import 'src/theme/variables';

.container {
  padding-bottom: 70px;
  padding-top: 20px;
  color: $brand-orange;

  svg {
    font-size: 1rem;
    margin-right: 0.2rem;
    cursor: pointer;
  }

  .text {
    color: $brand-orange;
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
  }
}
