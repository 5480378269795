@import 'src/theme/variables';

.main {
  // This safe-area padding is relevant for the iOS with notch. It works together with the "viewport" meta tag set in the index.html.
  // It has to have this property set: viewport-fit=cover
  @supports (padding-top: env(safe-area-inset-top)) {
    padding-top: calc(#{$navbar--height} + env(safe-area-inset-top));
  }

  flex: 1 0 auto;

  &.hideHeader {
    // This safe-area padding is relevant for the iOS with notch. It works together with the "viewport" meta tag set in the index.html.
    // It has to have this property set: viewport-fit=cover
    @supports (padding-top: env(safe-area-inset-top)) {
      padding-top: env(safe-area-inset-top);
    }
  }
}

.loading_progress{
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 100;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
}
