@import 'src/theme/variables';

.filterBtn {
  border-radius: $button-border-radius;
  height: 3rem !important;
}

.sortingBtn {
  height: 3rem !important;
}

.iconFilter {
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 5px;
  color: $brand-orange;
}
