@import 'src/theme/variables';

.table_wrapper {
  h1 {
    font-weight: 900;
    font-size: $space-24;
  }

  .toggle {
    margin: $space-32 0;
    display: flex;
    align-items: center;
  }

  .last_column {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.card{
  width: 100%;
  border: 1px solid $nightblue-lightest;
  border-radius: .5rem;
  padding: 2rem;
  text-align: center;
  background-color: $white;
}

.post_image{
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  position: relative;

  img{
    border-radius: .5rem;
  }

  .invisible{
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: .5rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    color: $white;
  }
}

.header{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
