@import '~@hse24/shared-components/styles/colors';
@import '~@hse24/shared-components/styles/spacings';
@import '~@hse24/shared-components/styles/fonts';
@import 'src/theme/variables';

.container {
  min-width: 150px;
  padding: $space-16 $space-24;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $color--white;
  border: 0.5px solid $color--lightgrey;
  border-radius: 10px;
  color: $color--nightblue;

  .value {
    font-size: $font-size-24;
    font-weight: 700;
    word-break: break-all;
    margin: 65px 0 0 0;
  }

  .top_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .hero {
      svg {
        width: 12.5px;
      }
    }

    .label {
      font-weight: 700;
      word-break: break-all;
      font-size: $font-size-14;
      margin: 0;
    }

    .sub_label {
      font-size: $font-size-14;
      word-break: break-all;
      font-weight: 400;
    }
  }
}