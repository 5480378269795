@import '~@hse24/shared-components/styles/_spacings.scss';
@import 'src/theme/variables';

.heading {
  margin-bottom: $space-16;
  display: flex;
  align-items: center;
}

.divider {
  background-color: $lightgrey;
}