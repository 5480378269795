@import 'src/theme/variables';

.icon {
  cursor: pointer;
}

.disabled_icon {
  color: $light-grey-2;
}

.week_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: $space-16;
  gap: calc($space-8 / 2);
  font-weight: $font-weight-7;
}
