@import 'src/theme/variables';

.dialog_header{
  background-color: $white;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  position: sticky;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;

  &.border{
    border-bottom: 1px solid $color--lightgrey;
    box-shadow: 0 2px 5px -3px rgba(0,0,0,0.1);
    transition-delay: 1s;
  }

  &>*:first-child{
    padding: 1rem .3rem 0 .3rem;
  }

  &>*:not(:first-child){
    padding: 1rem 1rem 0 1rem;
  }
}

.selected_products{
  display: flex;
  flex-direction: column;

  .selected_header{
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg{
      color: $black;
    }
  }

  .horizontal_list{
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    gap: 1.5rem;
    padding-bottom: .5rem;
  }
}

.done {
  display: flex;
  justify-content: flex-start;
  margin-left: 4px;
}

@media (max-width: $viewport--min-width-md) {
  .done {
   margin-left: 0;
  }
}
.title_padding{
  padding-left: 1rem;
}
.selected_products_number{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary;
  color: white;
  border-radius: 50%;
  width: $space-20;
  height: $space-20;
  padding-bottom: 2px;
}
.selected_products_string{
  margin-right: 0.5rem;
  line-height: 1rem;
}

.inline_display{
  display: flex;
  justify-content: flex-start;
}