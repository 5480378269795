@import 'src/theme/variables';

.appbar {
  border-bottom: solid 1px $grayLight;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: $white;
  min-height: calc(#{$navbar--height} + env(safe-area-inset-top));

  @supports (padding-top: env(safe-area-inset-top)) {
    padding-top: env(safe-area-inset-top);
  }
}

.toolbar {
  min-height: $navbar--height;
}

.header {
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;

  .text_center {
    display: none;

    @media (max-width: breakpoint-max(md)) {
      display: block;
    }
  }

  .title {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 1rem;

    @media (max-width: breakpoint-max(md)) {
      .text_left {
        display: none;
      }
    }
  }
}
