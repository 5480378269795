@import 'src/theme/variables';

.suggestion_item {
  display: flex;
  align-items: center;
  font-weight: 400;
  padding: 8px;
  column-gap: 8px;
  border-bottom: 1px solid #e4e4e4;
  cursor: pointer;
}
