@import 'src/theme/variables';

.table_filter{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  gap: 1rem;
  margin-top: $space-32;

  @media (max-width: $viewport--max-width-md) {
    margin-top: $space-20;
  }
}

.top_button_container{
  display: flex;
  max-width: 1552px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.inner_container {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  line-height: 20px;
  font-size: 16px;
}