@import 'src/theme/variables';

.chat_box {
  background: rgba(84, 90, 99, 1);
  width: 100%;
  height: calc(100vh - calc(#{$navbar--height} + env(safe-area-inset-top)));
  position: relative;
  margin-top: calc(#{$navbar--height} + env(safe-area-inset-top));

  .sticky_navbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
                    to bottom,
                    rgba(84, 90, 99, 1) 0%,
                    rgba(84, 90, 99, 0.8) 70%,
                    rgba(84, 90, 99, 0.1) 100%
    );
    padding: 0.5rem 1rem 2rem 1rem;
    z-index: 10;
  }

  .number_of_messages {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: rgba(211, 133, 255, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    color: $white;
  }

  .chat {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    overflow-y: auto;
    padding: 1rem;

    @media (max-width: $viewport--max-width-ms) {
      padding: 1rem 1rem 4rem;
    }

    &::-webkit-scrollbar {
      width: 0.375rem;
      height: 0.375rem;
    }

    &::-webkit-scrollbar-track {
      border-radius: 0.625rem;
      background: rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0.625rem;
      background: rgba(0, 0, 0, 0.2);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.4);
    }

    &::-webkit-scrollbar-thumb:active {
      background: rgba(0, 0, 0, 0.9);
    }

    &::-webkit-scrollbar-corner {
      display: none;
    }
  }

  .chat_font_adapter {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #545a63, #545a63);
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    width: 4rem;
    height: 14rem;
    border-radius: 3rem;
    padding: 1rem;

    display: grid;
    grid-template-rows: 24px 24px 1fr 24px;
    align-content: center;
    justify-content: center;
    gap: 0.5rem;
    grid-gap: 0.5rem;

    @media (max-width: $viewport--max-width-ms) {
      bottom: 1rem;
      right: .5rem;
      width: 3rem;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      color: $white;
    }
  }
}

.appbar {
  border-bottom: solid 1px $grayLight;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  min-height: $navbar--height;

  @supports (padding-top: env(safe-area-inset-top)) {
    padding-top: env(safe-area-inset-top);
  }
}

.toolbar {
  min-height: $navbar--height;
  width: 100%;
}

.header {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  width: 100%;
  overflow: hidden;
  color: $white;

  svg {
    color: $white;
  }

  .title {
    overflow: hidden;
    text-align: center;

    h6 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      width: 100%;
    }
  }
}


.hint_text {
  position: absolute;
  top: 50%;
  right: 1rem;
  color: white;
  white-space: nowrap;
  padding: .5rem;
  border-radius: .2rem;
  display: flex;

  @media (max-width: $viewport--max-width-ms) {
    bottom: 15%;
    top: 85%;
    right: 1.5rem;
  }

  svg {
    width: 2rem;
    height: 2rem;
  }
}