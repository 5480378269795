@import 'src/theme/variables';

.container {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  text-align: center;
  padding: $space-8 0;
  border-block: solid 1px rgba(128, 128, 128, 0.5);
}

.selected {
  background-color: $primary;
  color: $white;
  border-radius: $space-8;
}

.passed {
  color: $light-grey-2;
}

.day_container {
  flex: 1 0 0;
  cursor: pointer;
  padding: $space-12 $space-8;

  p {
    font-weight: $font-weight-7;
    width: 100%;
  }

  p:nth-child(2) {
    font-weight: $font-weight-4;
  }

}
