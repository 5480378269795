@import 'src/theme/variables';

.backToTop {
  position: fixed;
  bottom: calc(#{$bottom-navigation-height} + env(safe-area-inset-bottom));
  right: 1rem;
  padding: 0.5rem;
  background-color: $white;
  border-radius: $button-border-radius;
  cursor: pointer;
  box-shadow: 0 0 20px $color--box-shadow;
  transition: background-color 140ms ease-in-out;
  z-index: 1;
  margin-bottom: 10px;
}

.visible {
  animation: show 0.2s forwards;
}

.hidden {
  animation: hide 0.2s forwards;
}

.offscreen {
  right: -999rem;
}

@keyframes show {
  0% {
    opacity: 0;
  }

  1% {
    visibility: visible;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }

  99.999% {
    opacity: 0;
  }

  100% {
    visibility: hidden;
  }
}
