@import '~@hse24/shared-components/styles/breakpoints';
@import '~@hse24/shared-components/styles/fonts';
@import '~@hse24/shared-components/styles/spacings';
@import '~@hse24/shared-components/styles/spacings';
@import 'src/theme/variables';

$height-single: 1.33em;
$height-single-big: 1.38em;

.rounded {
  border-radius: $button-border-radius;
}

.priceReference {
  font-size: 12px;
  color: $color--iron;
}

.brand {
  @extend %text-with-fadeout;
  font-size: 12px;
  line-height: 16px;

  color: $color--iron;
  white-space: nowrap;

  height: $height-single;
  @include media-breakpoint-up(ms) {
    height: $height-single-big;
  }
}

.title {
  @extend %text-with-fadeout;

  color: $nightblue;
  margin-bottom: 0.25rem;
}

%text-with-fadeout {
  text-overflow: clip;
  overflow: hidden;
  position: relative;

  &::after {
    /* fading-out */
    content: '';

    position: absolute;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      $color--white
    ); // using ffffff00 due to transparent not working on iOS
    width: 3em;

    height: $height-single;
    @include media-breakpoint-up(ms) {
      height: $height-single-big;
    }
  }
}

.responsive-image-wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 125%;
  overflow: hidden;
  border-radius: $button-border-radius;

  &::after {
    border-radius: $button-border-radius;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      rgba(0, 0, 0, 0.04),
      rgba(0, 0, 0, 0.02),
      rgba(0, 0, 0, 0.03),
      rgba(0, 0, 0, 0.05)
    );
  }
}

.slcBadge {
  bottom: 8px;
  max-width: 90%;
  position: absolute;
  z-index: 1;
}

.discount {
  position: absolute;
  left: 0;
  top: 8px;
  border-radius: 0 $button-border-radius $button-border-radius 0;
  background-color: $brand-orange !important;
}

.sales-driver {
  position: absolute;
  left: 0;
  bottom: 0.5rem;
}

.free-shipping {
  font-size: $font-size-13;
  color: $brand-orange;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0.5rem 0 0;
  line-height: 1;
}
