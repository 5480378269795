@import 'src/theme/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: $space-24;

  .form {
    display: flex;
    flex-direction: column;
    gap: $space-24;
    padding: $space-24 $space-24 0 $space-24;

    .back {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: $space-24;

      h1 {
        font-weight: 700;
        font-size: $font-size-24;
      }
    }
  }
}
