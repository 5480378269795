@import 'src/theme/variables';

.container {
  position: relative;
  min-width: 150px;
  min-height: 150px;
  position: relative;

  .progress_spinner {
    width: 100%;
    height: 100%;
    border: 10px;
    border-radius: 50%;

    .progress_icon {
      position: absolute;
      top: 50%;
      transform: translate(0%, -50%);
      z-index: 2;

      .flame {
        font-size: 1rem;
        color: $curry-light;
      }

      .ellipse_icon {
        font-size: 1.05rem;
        color: $curry;
      }
    }
  }

  .inside_circle {
    width: calc(100% - 35px);
    height: calc(100% - 30px);
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    border-radius: 50%;
    z-index: 1;

    h1 {
      position: absolute;
      top: 20%;
      font-size: 42px;
      font-weight: 600;
      max-width: 75%;
      background: linear-gradient(270deg, $curry 9.27%, $curry-mid 51.52%, $curry-light 94.66%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .divider {
    position: absolute;
    width: 70%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 2px;
    color: $curry-lightest;
    margin-top: 35px;
    z-index: 3;
  }

  .hide_half_circle {
    width: 100%;
    height: 55%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 50px;
    position: absolute;
    top: 50%;
    background-color: $white;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    z-index: 2;

    h2 {
      font-size: 18px;
      font-weight: 700;
    }

    span {
      font-size: 16px;
      font-weight: 700;
      color: $nightblue-light;
    }

    .left_half_circle_end,
    .right_half_circle_end {
      height: 10px;
      width: 17px;
      position: absolute;
      top: 0;
      border-radius: 50% /100% 100% 0 0;
      transform: rotate(180deg);
    }

    .left_half_circle_end {
      left: 0;
    }

    .right_half_circle_end {
      right: 0;
    }
  }

  .start_ellipse {
    position: absolute;
    font-size: 1.05rem;
    top: 47%;
    z-index: 4;

    color: $curry;
  }

  .end_flame {
    position: absolute;
    top: 34%;
    right: -18px;
    z-index: 4;
    color: $curry-light;
  }
}
