@import 'src/theme/variables';

.show_cover {
  &.space {
    margin-top: $space-16;
  }

  .cover_card {
    position: relative;
    border-radius: 4px;
    border: 2px solid $lightgrey;

    .cover_selected_img {
      width: 100%;
      height: 100%;
    }

    .floating_button {
      position: absolute;
      bottom: $space-32;
      left: 50%;
      transform: translate(-50%, 0);
      background-color: $primary;
      color: $white;
      width: $space-48;
      height: $space-48;

      &.edit {
        color: black;
        background-color: $white;
      }
    }
  }

  .error_cover_card {
    @extend .cover_card;
    border: 2px solid $venetian_red;
  }

  .large_cover_card {
    width: min(343px, 100%);
    height: 428.75px;

    @media (min-width: $viewport--min-width-ms) {
      width: min(430px, 100%);
      height: 537px;
    }

    @media (min-width: $viewport--min-width-md) {
      width: min(523.2px, 100%);
      height: 654px;
    }

    .card {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .ratio_26_10_cover_card {
    width: min(416px, 100%);
    height: 160px;

    .card {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
    }

    .image_text {
      position: absolute;
      top: $space-48;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.cover_card {
      border: 2px dashed $lightgrey;
    }

    &.error_cover_card {
      border: 2px dashed $venetian_red;
    }
  }

  .image_container {
    display: flex;
    flex-direction: column;
    gap: $space-8;
  }

  .ratio_text {
    color: $iron;
  }

  .img_input {
    display: none;
  }
}


.title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 8px;
}

.menu_item {
  pointer-events: none;
}

.secondary_text {
  color: $iron;
}

.error_secondary_text {
  color: $venetian_red;
}
