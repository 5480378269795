@import 'src/theme/variables';

.dialog_paper {
  background: linear-gradient(180deg, #fff 0%, #f4f9ff 100%), #fff;

  &.desktop {
    width: 43rem;
  }
}

.dialog_body {
  height: 47rem;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;

  @media (max-width: $viewport--min-width-ms) {
    height: auto;
    width: auto;
  }

  .content {
    margin: auto;
    max-width: 25rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;

    .image {
      height: 534px;

      &.small {
        height: 394px;
      }

      img {
        height: 100%;
      }
    }

    @media (max-width: $viewport--min-width-ms) {
      max-width: 100%;
      width: 100%;
    }
  }
}

.dialog_actions {
  width: 100%;
  padding: 2.5rem 3.5rem;
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 1fr 2fr;
  background: transparent;

  @media (max-width: $viewport--min-width-ms) {
    padding: 1rem;
  }

  .button {
    margin-left: 0;
    display: flex;
    justify-content: center;
    @media (max-width: $viewport--min-width-ms) {
      width: 100%;
    }

    button {
      padding: 0.5rem 1rem;
      width: 100%;
      border: 1px solid $primary;

      @media (max-width: $viewport--min-width-ms) {
        padding: 0.35rem 1rem;
        width: 80%;
      }
    }
  }

  .step_indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-left: 0;

    .step_dot {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background: $nightblue-lightest;

      &.selected {
        background: $primary;
      }
    }
  }
}
